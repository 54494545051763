import React from "react";
import styles from "./JobModal.module.scss"
import { Button, Col, Modal } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Positions, { IPosition } from "..";
import RightArrow from "../../../../assets/right-arrow.png";
import CloseBlack from "../../../../assets/close_black.png";

const JobModal = ({selectedJob, setSelectedJob}:{selectedJob:IPosition; setSelectedJob:any;}) =>{
  
  const handleClose = ()=>{
    setSelectedJob(undefined)
  }
  
  const isShowModal = (!!selectedJob) ? true : false;
  return (
    <Modal show={isShowModal} className={styles.modal} onHide={handleClose}>
      <Modal.Body>

        <span className={styles["close-btn"]} onClick={handleClose}>
          <img src={CloseBlack}/>
        </span>
        <Row>
          <Col className={styles.change_img}>
            <div className={styles.jobs}>
              <div className={styles.img}>

              </div>
              <div className={styles.bsn}>
                <Positions setSelectedJob={setSelectedJob}/>
              </div>
            </div>
          </Col>
          <Col>
            <div className={styles.content}>
              <h3 className={styles.title}>{selectedJob.name}</h3>
              
              {selectedJob.description1 && (
                <p className={styles["f-para"]}>
                  {selectedJob.description1}
                </p>
              )}
           
              {selectedJob.description2 && (
                <p className={styles["f-para2"]}>
                  {selectedJob.description2}
                </p>
              )}
        
              <hr/>

              {selectedJob.your_are1 && (
                <div className={styles["col-div1"]}>
                  <h4>YOU ARE:</h4>
                  {selectedJob &&  selectedJob.your_are1?.map((text, index)=>{
                      return(
                      <p key={index}> {text} </p>
                      )
                    })
                  }

                  {selectedJob.ideal_candidate &&
                    <h4>THE IDEAL CANDIDATE:</h4>
                  }

                  {selectedJob && selectedJob.ideal_candidate?.map((text, index)=>{
                      return(
                      <p key={index}> {text} </p>
                      )
                    })
                  }
                </div>
              )}

              {selectedJob.you_will && (
                <div className={styles["col-div1"]}>
                  <h4>YOU WILL:</h4>
                  {selectedJob &&  selectedJob.you_will?.map((text, index)=>{
                      return(
                      <p key={index}> {text} </p>
                      )
                    })
                  }

                  {selectedJob.ideal_candidate &&
                    <h4>THE IDEAL CANDIDATE:</h4>
                  }

                  {selectedJob && selectedJob.ideal_candidate?.map((text, index)=>{
                      return(
                      <p key={index}> {text} </p>
                      )
                    })
                  }
                </div>
              )}
              
              {selectedJob.job_is && (
                <>
                  <hr/>
                  <h3 className={styles.title}>the job is:</h3>
                  <div className={styles["col-div"]}>
                    <p  dangerouslySetInnerHTML={{__html: selectedJob.job_is}}></p>
                  </div>
                </>
              )}

              <div className={styles["send-btn"]}>
                <button><img src={RightArrow}/> <a href="mailto:jobs@popculturemedia.co"> send resume </a></button>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default JobModal;