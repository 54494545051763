import React from "react";

const About = () =>{
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

export default About;