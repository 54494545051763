import { FunctionComponent } from "react";
import React from "react";
import styles from "./LandingPage.module.css";

const LandingPage: FunctionComponent = () => {
 
  return (
    <div className={styles.landingPage}>
      <div className={styles.navigation}>
        <div className={styles.logo}>
          <a href="./get"><img className={styles.unionIcon} alt="" src="/union.svg" /></a>
        </div>
        <div className={styles.button}>
          <div className={styles.button1}>
            <div className={styles.content}>
              <img className={styles.icon} alt="" src="/icon.svg" />
              <a href="https://merchant.kols.asia"><div className={styles.domaincom}>For Merchant</div></a>
              <img className={styles.icon1} alt="" src="/icon1.svg" />
            </div>
          </div>
          <div className={styles.appstore}>
            <a href="https://apps.apple.com/us/app/kols-asia/id1594976667" target="_blank"> <img className={styles.appstoreIcon} alt="" src="/appstore.svg" /> </a>
            <a href="https://play.google.com/store/apps/details?id=com.kolsasia.kol" target="_blank"> <img className={styles.appstoreIcon} alt="" src="/googleplay.svg" /> </a>
          </div>
        </div>
      </div>
      <div className={styles.footerParent}>
        <div className={styles.frameChild} />

        <div className={styles.footer}>

          <div className={styles.copywrite2023KaTechnologiParent}>
            <div className={styles.privacyPolicy}>
              Copywrite © 2023 KA Technologies Sdn Bhd
            </div>
            <div className={styles.button3}>
              <a href="./privacy">
                <div className={styles.button4}>
                  <div className={styles.content}>
                    <img className={styles.icon1} alt="" src="/icon1.svg" />
                    <div className={styles.privacyPolicy}>Privacy Policy</div>
                    <img className={styles.icon1} alt="" src="/icon1.svg" />
                  </div>
                </div>
              </a>
              <a href="./tos">
                <div className={styles.button4}>
                  <div className={styles.content}>
                    <img className={styles.icon1} alt="" src="/icon1.svg" />
                    <div className={styles.privacyPolicy}>Terms of Services</div>
                    <img className={styles.icon1} alt="" src="/icon1.svg" />
                  </div>
                </div>
              </a>
              <a href="https://www.kols.asia/">
                <div className={styles.button4}>
                  <div className={styles.content}>
                    <img className={styles.icon1} alt="" src="/icon1.svg" />
                    <div className={styles.privacyPolicy}>About kols.asia</div>
                    <img className={styles.icon1} alt="" src="/icon1.svg" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.groupParent}>
          <div className={styles.mockupIphone131Parent}>
            <img 
              className={styles.group8983}
              alt=""
              src="/group8983@2x.png"
            />
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv}>
              <div className={styles.areYouACreatorParent}>
                <b className={styles.areYouA}>Are you a creator?</b>
                <div className={styles.monetizeYourContent}>
                  Monetize your content through brand partnerships, the
                  intuitive KOLs.Asia interface allows you to sort through
                  campaigns from your favorite brands and find the ones that are
                  most relevant to your audience.
                </div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.ellipseParent}>
                  <div className={styles.frameItem} />
                  <div
                    className={styles.directChat}
                  >{`Direct chat & Communication.`}</div>
                </div>
                <div className={styles.ellipseGroup}>
                  <div className={styles.frameItem} />
                  <div className={styles.privacyPolicy}>
                    Live campaign tracking.
                  </div>
                </div>
                <div className={styles.ellipseGroup}>
                  <div className={styles.frameItem} />
                  <div className={styles.privacyPolicy}>
                    Real time alerts – Never forget a deadline.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.getKolsasiaNowParent}>
              <b className={styles.getKolsasiaNow}>Get KOLs.Asia now!</b>
              <div className={styles.appstore}>
                <a href="https://apps.apple.com/us/app/kols-asia/id1594976667" target="_blank"><img
                  className={styles.appstoreIcon}
                  alt=""
                  src="/appstore.svg"
                /></a>
                <a href="https://play.google.com/store/apps/details?id=com.kolsasia.kol" target="_blank"><img
                  className={styles.appstoreIcon}
                  alt=""
                  src="/googleplay.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent2}>
          <div className={styles.loremIpsumDolorSitAmetCoParent}>
            
            <div className={styles.frameParent3}>
              <div className={styles.areYouACreatorParent}>
                <b className={styles.areYouA}>Are you a brand owner?</b>
                <div className={styles.monetizeYourContent}>
                  No more agency fees, manage your campaigns with ease!
                </div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.ellipseParent}>
                  <div className={styles.frameItem} />
                  <div className={styles.directChat}>
                    Simple set up, briefing process and KOL selection leaves you in full control.
                  </div>
                </div>
                <div className={styles.ellipseGroup}>
                  <div className={styles.frameItem} />
                  <div className={styles.privacyPolicy}>
                    <p className={styles.clearReportingOf}>Clear reporting of results, know where you’re getting the most value.</p>
                  </div>
                </div>
                <div className={styles.ellipseGroup}>
                  <div className={styles.frameItem} />
                  <div className={styles.privacyPolicy}>
                    Live chat functionality ensures KOL partners stay on track.
                  </div>
                </div>
                <div className={styles.ellipseParent4}>
                  <div className={styles.frameItem} />
                  <div className={styles.domaincom}>Live chat with KOLs</div>
                </div>
                <div className={styles.ellipseParent4}>
                  <div className={styles.frameItem} />
                  <div className={styles.domaincom}>Live campaign tracking</div>
                </div>
                <div className={styles.ellipseParent4}>
                  <div className={styles.frameItem} />
                  <div className={styles.domaincom}>
                    Never miss an important deadline
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.registerAsAMerchantNowParent}>
              <b className={styles.getKolsasiaNow}>
                Register as a merchant now!
              </b>
              <a href="https://merchant.kols.asia">
                <div className={styles.button8}>
                  <div className={styles.content5}>
                    <img className={styles.icon1} alt="" src="/icon2.svg" />
                    <div className={styles.domaincom}>Register</div>
                    <img className={styles.icon11} alt="" src="/icon3.svg" />
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className={styles.groupContainer}>
              <img
                className={styles.imagegroup8987}
                alt=""
                src="/group8987@2x.png"
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
