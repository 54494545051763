import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./StoriesGrid.module.scss"
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import bannerImage from "../../assets/hero.png";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
//taiwan-tourism
import taiwanmain from "../../assets/taiwan-tourism/main.jpg";
import taiwan1 from "../../assets/taiwan-tourism/1.jpg";
import taiwan2 from "../../assets/taiwan-tourism/2.jpg";
import taiwan3 from "../../assets/taiwan-tourism/3.jpg";
import taiwan4 from "../../assets/taiwan-tourism/4.jpg";
import taiwan5 from "../../assets/taiwan-tourism/5.jpg";
import taiwan6 from "../../assets/taiwan-tourism/6.jpg";
import taiwan7 from "../../assets/taiwan-tourism/7.jpg";
import taiwan8 from "../../assets/taiwan-tourism/8.jpg";
import taiwan9 from "../../assets/taiwan-tourism/9.jpg";
import taiwan10 from "../../assets/taiwan-tourism/10.jpg";
import taiwan11 from "../../assets/taiwan-tourism/11.jpg";
import taiwan12 from "../../assets/taiwan-tourism/12.jpg";
import taiwan13 from "../../assets/taiwan-tourism/13.jpg";
import taiwan14 from "../../assets/taiwan-tourism/14.jpg";
import taiwan15 from "../../assets/taiwan-tourism/15.jpg";
import taiwan16 from "../../assets/taiwan-tourism/16.jpg";
import taiwan17 from "../../assets/taiwan-tourism/17.jpg";
import taiwan18 from "../../assets/taiwan-tourism/18.jpg";
//biotherm
import biothermmain from "../../assets/biotherm/main.png";
import biotherm1 from "../../assets/biotherm/1.png";
import biotherm2 from "../../assets/biotherm/2.png";
import biotherm3 from "../../assets/biotherm/3.png";
import biotherm4 from "../../assets/biotherm/4.png";
import biotherm5 from "../../assets/biotherm/5.png";
import biotherm6 from "../../assets/biotherm/6.png";
import biotherm7 from "../../assets/biotherm/7.png";
import biotherm8 from "../../assets/biotherm/8.png";
import biotherm9 from "../../assets/biotherm/9.png";
//lancome
import lancomemain from "../../assets/lancome/main.jpg";
import lancome1 from "../../assets/lancome/1.jpg";
import lancome2 from "../../assets/lancome/2.jpg";
import lancome3 from "../../assets/lancome/3.jpg";
import lancome4 from "../../assets/lancome/4.jpg";
import lancome5 from "../../assets/lancome/5.jpg";
import lancome6 from "../../assets/lancome/6.jpg";
import lancome7 from "../../assets/lancome/7.jpg";
import lancome8 from "../../assets/lancome/8.jpg";
import lancome9 from "../../assets/lancome/9.jpg";
import lancome10 from "../../assets/lancome/10.jpg";
import lancome11 from "../../assets/lancome/11.jpg";
//pinko
import pinkomain from "../../assets/pinko/main.jpg";
import pinko1 from "../../assets/pinko/1.jpg";
import pinko2 from "../../assets/pinko/2.png";
import pinko3 from "../../assets/pinko/3.jpg";
//saza-rice
import sazaricemain from "../../assets/sazarice/main.png";
import sazarice1 from "../../assets/sazarice/1.jpg";
import sazarice2 from "../../assets/sazarice/2.jpg";
import sazarice3 from "../../assets/sazarice/3.jpg";
import sazarice4 from "../../assets/sazarice/4.jpg";
import sazarice5 from "../../assets/sazarice/5.jpg";
import sazarice6 from "../../assets/sazarice/6.jpg";
import sazarice7 from "../../assets/sazarice/7.jpg";
import sazarice8 from "../../assets/sazarice/8.jpg";
import sazarice9 from "../../assets/sazarice/9.jpg";
//panasonic
import panasonicmain from "../../assets/panasonic/main.png";
import panasonic1 from "../../assets/panasonic/1.png";
import panasonic2 from "../../assets/panasonic/2.png";
import panasonic3 from "../../assets/panasonic/3.jpg";
import panasonic4 from "../../assets/panasonic/4.jpg";

//moschino
import moschinomain from "../../assets/moschino/1.png";
import moschino1 from "../../assets/moschino/2.png";
import moschino2 from "../../assets/moschino/3.png";
//kose
import kosemain from "../../assets/kose/main.png";
import kose1 from "../../assets/kose/1.jpg";
import kose2 from "../../assets/kose/2.png";
import kose3 from "../../assets/kose/3.jpg";
import kose4 from "../../assets/kose/4.jpg";
import kose5 from "../../assets/kose/5.jpg";
import kose6 from "../../assets/kose/6.jpg";
import kose7 from "../../assets/kose/7.jpg";
import kose8 from "../../assets/kose/8.jpg";
import kose9 from "../../assets/kose/9.jpg";
import kose10 from "../../assets/kose/10.jpg";
import kose11 from "../../assets/kose/11.jpg";
import kose12 from "../../assets/kose/12.jpg";
import kose13 from "../../assets/kose/13.jpg";
//swatch
import swatchmain from "../../assets/swatch/main.jpg";
import swatch1 from "../../assets/swatch/1.jpg";
import swatch2 from "../../assets/swatch/2.jpg";
import swatch3 from "../../assets/swatch/3.jpg";
import swatch4 from "../../assets/swatch/4.jpg";
import swatch5 from "../../assets/swatch/5.jpg";
import swatch6 from "../../assets/swatch/6.jpg";
import swatch7 from "../../assets/swatch/7.jpg";
import swatch8 from "../../assets/swatch/8.jpg";
//eucerin
import eucerinmain from "../../assets/eucerin/main.jpg";
import eucerin1 from "../../assets/eucerin/1.jpg";
import eucerin2 from "../../assets/eucerin/2.jpg";
import eucerin3 from "../../assets/eucerin/3.jpg";
import eucerin4 from "../../assets/eucerin/4.jpg";
import eucerin5 from "../../assets/eucerin/5.jpg";
//boost
import boostmain from "../../assets/boost/main.jpg";
import boost1 from "../../assets/boost/1.jpg";
import boost2 from "../../assets/boost/2.jpg";
import boost3 from "../../assets/boost/3.jpg";
import boost4 from "../../assets/boost/4.jpg";
import boost5 from "../../assets/boost/5.jpg";
import boost6 from "../../assets/boost/6.jpg";
import boost7 from "../../assets/boost/7.jpg";
//shokubutsu
import shokubutsumain from "../../assets/shokubutsu/main.jpg";
import shokubutsu1 from "../../assets/shokubutsu/1.jpg";
import shokubutsu2 from "../../assets/shokubutsu/2.jpg";
import shokubutsu3 from "../../assets/shokubutsu/3.jpg";
import shokubutsu4 from "../../assets/shokubutsu/4.jpg";
import shokubutsu5 from "../../assets/shokubutsu/5.jpg";
import shokubutsu6 from "../../assets/shokubutsu/6.jpg";
import shokubutsu7 from "../../assets/shokubutsu/7.jpg";
//panasonic cooking
import panasoniccookmain from "../../assets/panasonic-cooking/main.jpg";
import panasoniccook1 from "../../assets/panasonic-cooking/1.jpg";
import panasoniccook2 from "../../assets/panasonic-cooking/2.jpg";
import panasoniccook3 from "../../assets/panasonic-cooking/3.jpg";
import panasoniccook4 from "../../assets/panasonic-cooking/4.jpg";
//mrcb
import mrcbmain from "../../assets/mrcb/main.jpg";
import mrcbmain1 from "../../assets/mrcb/1.jpg";
import mrcbmain2 from "../../assets/mrcb/2.jpg";
import mrcbmain3 from "../../assets/mrcb/3.jpg";
import mrcbmain4 from "../../assets/mrcb/4.jpg";
import mrcbmain5 from "../../assets/mrcb/5.jpg";
import mrcbmain6 from "../../assets/mrcb/6.jpg";
import mrcbmain7 from "../../assets/mrcb/7.jpg";


interface ISentiment {
  username?:string;
  username1?:string;
  comment?:string;
  usernameMain?:string;
  small_comment?:string;
  small_comment1?:string;
}
export interface ISuccessStory {
  id:number;
  name:string;
  image:string;
  brief_description:string;
  full_description:string;
  campaign_period:string;
  total_kols_engaged:string;
  deliverables:string;
  total_content:number;
  total_reach:number;
  total_engagement:number;
  product_images:Array<string>;
  sentiments:Array<ISentiment>;
}

export type ISuccessStories = Array<ISuccessStory>;

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }


const StoriesGrid = ({title}:{title:string}) =>{

  const [isShowImageIndex, setIsShowImage] = useState<number>()

  const stories:ISuccessStories = [{
    id:1,
    name:"Taiwan Tourism",
    image: taiwanmain,
    brief_description:"",
    full_description:"In collaboration with Taiwan Tourism Board, we flew a few A-List KOLs across different countries to experience Taiwan through local delicacies and attractions",
    campaign_period:"4 months",
    total_kols_engaged:"4 A-List",
    deliverables:"Overseas trip + Contents on different social media platforms",
    total_content:20,
    total_reach: 4725300,
    total_engagement: 703290,
    product_images:[ taiwan1, taiwan2, taiwan3, taiwan4, taiwan5, taiwan6, taiwan7, taiwan8, taiwan9, taiwan10 , taiwan11,
      taiwan12, taiwan13, taiwan14, taiwan15, taiwan16, taiwan17, taiwan18
    ],
    sentiments:[],
  },{
    id:2,
    name:"Biotherm",
    image: biothermmain,
    brief_description:"Biotherm Life Plankton Clear Essence Launch",
    full_description:"KOLs were invited to celebrate the new launch of Life Plankton Clear Essence and to highlight key benefits of the product.",
    campaign_period:"1 month",
    total_kols_engaged:"30 Micro Tier - A-Lists",
    deliverables:"Event coverage + IG postings",
    total_content:60,
    total_reach:660084,
    total_engagement:9980,
    product_images:[ biotherm1,biotherm2, biotherm3, biotherm4, biotherm5, biotherm6, biotherm7,
      biotherm8, biotherm9
    ],
    sentiments:[{
      username:"itselizabethtran",
      small_comment:"This essence sounds amazing And love your outfit soo much!",
      usernameMain: "imjuvenca",
      comment: "@itselizabethtran hehe Thanks"
    }, {
      username: "shaunven",
      small_comment: "Will it work on my sand paper skin?"
    }, {
      username: "ruxandrasoare",
      small_comment: "Much needed after plane flights, yes"
    },{
      username: "shrazzi",
      small_comment: "I'd love to see a tutorial on this - maybe IGTV",
      usernameMain: "imjuvenca",
      comment: "@shrazzi hahahahaha I will try maybe"
    }],
  },{
    id:3,
    name:"Lancome",
    image: lancomemain,
    brief_description:"Lancome Clarifique Dual essence launch",
    full_description:"To generate buzz on the new product launch, Lancome collaborated with top KOLs from the beauty scene on a 7-day challenge to showcase their transformations using the product",
    campaign_period:"2 months",
    total_kols_engaged:"15 Mid Tier - Top Tier",
    deliverables:"IG multi-post",
    total_content:15,
    total_reach:359357,
    total_engagement:46021,
    product_images:[lancome1,lancome2,lancome3, lancome4, lancome5, lancome6, lancome7, lancome8, lancome9 ,lancome10,lancome11
    ],
    sentiments:[{
      username:"shamieraosment",
      small_comment:"Wow lancome..best tu..",
      username1: "mekonie_",
      small_comment1:"Best tak?",
      usernameMain: "frhdafri",
      comment: "@mekonie_ besttt! Nnt hujung bulan ni faa share before and after photo. Bykk nampak beza"
    }, {
      username: "my_soultravels",
      small_comment: "I love Lancome products. This one looks like a great product too."
    }, {
      username: "iamakhilsharma",
      small_comment: "That's really cool. Sharing it with my wife"
    }, {
      username: "nadiasamjohari",
      small_comment: "Best tak.. Belum try lagi jenama ni",
      usernameMain: "frhdafri",
      comment: "@nadiasamjohari best kak nnt kite share perubahan guna ni. Byk juga beza"
    }],
  },{
    id:4,
    name:"Pinko",
    image: pinkomain,
    brief_description:"Bluebell seeding",
    full_description:"This campaign was to promote Pinko's AW19 launch",
    campaign_period:"11th - 12th October 2019",
    total_kols_engaged:"4",
    deliverables:"1x IG Photo",
    total_content:4,
    total_reach:112441,
    total_engagement:14968,
    product_images:[ pinko1, pinko2, pinko3],
    sentiments:[],
  },{
    id:5,
    name:"Saza Rice",
    image: sazaricemain,
    brief_description:"Saza Rice Golden Peonies",
    full_description:"Sabah-based KOLs were invited to the launch of Saza Rice Golden Peonies and to experience an educational tour on how rice is actuall made. Contents were cretaed to highlight the new launch and some on-going promotions",
    campaign_period:"3 months",
    total_kols_engaged:"15 Micro - Mid Tier",
    deliverables:"Event coverage + IG photos + IG videos",
    total_content:32,
    total_reach:261885,
    total_engagement:50882,
    product_images:[sazarice1, sazarice2, sazarice3,sazarice4, sazarice5, sazarice6, sazarice7, sazarice8, sazarice9],
    sentiments:[{
      username:"myrabiusing",
      small_comment:"Omg love nasi so much",
      username1:"ellectricc",
      small_comment1: "yes pg masak nasi sia lapar" 
    }, {
      username: "andreaglorie",
      small_comment: "i just had 3 plates of rice so yes... nasi is makanan paling sadap",
      usernameMain: "itsbriegracia",
      comment: "@andreaglorie gila la kau paling manang",
    }, {
      username: "feeqsays",
      small_comment: "Bila beras tu sedap, makan dengan kicap & telur pun dah sedap hahaha",
      usernameMain: "ohfishiee",
      comment: "@feeqsays totally agree!!"
    }, {
      username: "reavle",
      small_comment: "@nmjha.x ba tlg beli beras utk rumah koi spy dpt tu rice dispenser hahah"
    }, {
      username: "charlottefong",
      small_comment: "Sa mai rice dispenser!"
    }, {
      username: "antee_ratu_c_heart",
      small_comment: "Brapa harga dia kunu?"
    }],
  },{
    id:6,
    name:"Panasonic Beauty",
    image: panasonicmain,
    brief_description:"Panasonic x Kuala Lumpur Fashion Week",
    full_description:"Panasonic invited a group of A-List influencers to be a part of their collaboration with KLFW. They all went home with the iconic Panasonic Nanoe N98 Hair Dryer and created how to videos featureing the product's different functions",
    campaign_period:"1 month",
    total_kols_engaged:"8 A-List",
    deliverables:"Event coverage + IG photos + IG videos",
    total_content:24,
    total_reach:916274,
    total_engagement:177805,
    product_images:[panasonic1, panasonic2, panasonic3, panasonic4 ],
    sentiments:[{
      username:"woonsoonteng",
      small_comment:"Yes it's a very good hair dryer I have been using for more than 30 years"
    }, {
      username: "marionchin",
      small_comment: "@nicckane @yishyuenn Need this holy grail cuz my nothing can save my hay-like hairexcept this!"
    }, {
      username: "yan_tattooist",
      small_comment: "I want the tok kong hair dryer because so ngam my hair dryer sudah rosak. So ngam kittie doing giveaway! Just trying my luck! @jasonwong_tattooist @yying.tattoo__"
    }],
  },{
    id:7,
    name:"Love Moschino",
    image: moschinomain,
    brief_description:"Bluebell seeding",
    full_description:"Campaign was done to promote brand awareness",
    campaign_period:"16th - 24th July 2019",
    total_kols_engaged:"3",
    deliverables:"1x IG Photo",
    total_content:3,
    total_reach:57493,
    total_engagement:7646,
    product_images:[moschino1, moschino2, moschinomain],
    sentiments:[{
      username:"katwalksf",
      small_comment:"Love that dress"
    }, {
      username: "ghinaghandourofficial",
      small_comment: "Love ur dress"
    }, {
      username: "araujo_jr22",
      small_comment: "Moschino baby",
      usernameMain: "pressthebutton.tv",
      comment: "@araujo_jr22 oh yay"
    }],
  }, {
    id:8,
    name:"Kose",
    image: kosemain,
    brief_description:"Kose Sekkisei Essential Souffle Launch",
    full_description:"For the biggest launch of the year, Kose Malaysia worked with KOLs accross all tiers for the official media launch, reviews and continuos promotional shoutouts",
    campaign_period:"6 months",
    total_kols_engaged:"40 Nano - A-List",
    deliverables:"Event coverage + IG photos + IG stories",
    total_content:50,
    total_reach:778670,
    total_engagement:89111,
    product_images:[kose1,kose2,kose3,kose4,kose5,kose6,kose7,kose8,kose9,kose10,kose11,kose12,kose13],
    sentiments:[{
      username:"jinyeng",
      small_comment:"Yes yes reviews!!!",
      username1: "aydanoordin__",
      small_comment1: "forr oily skin or dry skin ?"
    }, {
      username: "naziela_29",
      small_comment: "How much??"
    }, {
      username: "jen.a.hu",
      small_comment: "Your skin is glowing!!"
    }],
  },{
    id:9,
    name:"Swatch",
    image: swatchmain,
    brief_description:"Swatch Big Bold",
    full_description:"In collaboration with Swatch, these fashion and lifestyle based KOLs were chosen to create lifestyle images featuring the Big Bold range",
    campaign_period:"2 months",
    total_kols_engaged:"10 Mid Tier - Top Tier",
    deliverables:"IG photos",
    total_content:20,
    total_reach:293309,
    total_engagement:58128,
    product_images:[swatch1, swatch2, swatch3,swatch4,swatch5,swatch6,swatch7,swatch8],
    sentiments:[{
      username:"shahad_abu_sharar",
      small_comment:"Wow I like this wristwatch Nice pic",
      username1: "mr_arrogant84",
      small_comment1: "Ok jam ni lawa!!!!!!"
    }, {
      username: "himel2010",
      small_comment: "Really love the watch brother. It looks spectacular"
    }, {
      username: "mrsyayaz",
      small_comment: "Wow swatch! design baru",
      usernameMain: "shukrimarch",
      comment: "@mrsyayaz yeah... #swatchbigbold beli lah utk shuben"
    }, {
      username: "aidahusnina_",
      small_comment: "Wahhhhhh cantik laaa jam dia",
      usernameMain: "shukrimarch",
      comment: "@aidahusnina_ beli lah utk islah haahah"
    }],
  },{
    id:10,
    name:"eucerin",
    image: eucerinmain,
    brief_description:"Eucerin ProAcne Solution",
    full_description:"Testimonial based campaign where the KOLs were asked to show a'before vs after' using the product for a week",
    campaign_period:"1 month",
    total_kols_engaged:"26 Mid Tier - Top Tier",
    deliverables:"IG multi-post",
    total_content:26,
    total_reach:1099793,
    total_engagement:87201,
    product_images:[eucerin1,eucerin2,eucerin3,eucerin4,eucerin5],
    sentiments:[{
      username:"ftinasreen_ @ntshafsl",
      small_comment:"try this",
      username1:"shaz_ezman",
      small_comment1:"Aira untuk scars okay ke ?"
    }, {
      username: "dicksontiw",
      small_comment: "Really good? I will try this"
    }, {
      username: "sni0409",
      small_comment: "Seres ah berkesan nak cuba ni",
      username1: "fariqh92",
      small_comment1: "A.I Clearing Treatment tu sumpah berkesan!"
    }],
  },{
    id:11,
    name:"Boost",
    image: boostmain,
    brief_description:"Boost App",
    full_description:"In partnesrship with Boost App, these KOLs showcased how they prepared for the Ramadhan season from shopping for Raya outfits online to sending e-duit raya to friends and family overseas.",
    campaign_period:"1 month",
    total_kols_engaged:"20 Mid Tier - Top tier",
    deliverables:"IG photos",
    total_content:40,
    total_reach:234657,
    total_engagement:64452,
    product_images:[boost1,boost2,boost3,boost4,boost5,boost6,boost7],
    sentiments:[],
  },{
    id:12,
    name:"Shokubutsu",
    image: shokubutsumain,
    brief_description:"Shokubutsu Mochi Hada",
    full_description:"KOLs were invited to a spa day for the launch of Shokubutsu Mochi Hada while experiencing its Japanese roots with Onsen bath and massage",
    campaign_period:"6 months",
    total_kols_engaged:"60 Micro - Mid Tier",
    deliverables:"IG photos + IG videos",
    total_content:60,
    total_reach:308843,
    total_engagement:44030,
    product_images:[shokubutsu1, shokubutsu2, shokubutsu3,shokubutsu4,shokubutsu5,shokubutsu6,shokubutsu7],
    sentiments:[{
      username:"davidddodle",
      small_comment:"Have been using this brand for yearssssss"
    }, {
      username: "callielow",
      small_comment: "Dman. Get me a bottle of that Shokubutsu",
      username1: "gaylenkasai",
      small_comment1: "My Fav shower oil"
    }, {
      username: "keeracakedherface",
      small_comment: "How much is this eh",
      username1: "babeglowish",
      small_comment1: "Oh so these are their latest products right?",
    }, {
      username: "elnishae",
      small_comment: "@shokubutsumalaysia is this new? I haven't seen it yet or maybe I don't go out often",
      username1: "frhdafri",
      small_comment1: "i cari dkt guardian and watson xde :( not sure if outlet i pergi xde ke apa"
    }],
  },{
    id:13,
    name:"Panasonic",
    image: panasoniccookmain,
    brief_description:"Panasonic Cooking",
    full_description:"KOLs were asked to produce a series of recipe-based contents using different Panasonic Cooking appliances",
    campaign_period:"6 months",
    total_kols_engaged:"4 Top Tier - A-List",
    deliverables:"IG videos + IG photos",
    total_content:25,
    total_reach:1002600,
    total_engagement:139938,
    product_images:[panasoniccook1, panasoniccook2, panasoniccook3,panasoniccook4],
    sentiments:[{
      username:"p._ccoloailyn @liceryl",
      small_comment:"malaysia selling the big cubie. Check it out",
      username1: "carol.lamwy",
      small_comment1: "I want that fridge"
    }, {
      username: "agnes_yc91",
      small_comment: "Is that oven available in malaysia already?",
      usernameMain: "cheeserland",
      comment: "@agnes_yc91 yes"
    }, {
      username: "suesuelin",
      small_comment: "Omg cheesie! I've been eyeing this!",
      usernameMain: "cheeserland",
      comment: "@suesuelin get get get!!!"
    }, {
      username: "chinmf",
      small_comment: "@elecherlee may i know what is the model?"
    }],
  },{
    id:14,
    name:"MRCB",
    image: mrcbmain,
    brief_description:"MRCB Sentral Suites",
    full_description:"MRCB took an a soft-sell approach and worked with a group of lifestyle KOLs to promote their Sentral Suites through interesting contents revolving the themes of Eat, Play and Work in KL Sentral.",
    campaign_period:"3 months",
    total_kols_engaged:"5 Mid - A-List",
    deliverables:"IG videos + IG photos",
    total_content:10,
    total_reach:840692,
    total_engagement:98494,
    product_images:[mrcbmain1,mrcbmain2,mrcbmain3,mrcbmain4,mrcbmain5,mrcbmain6,mrcbmain7],
    sentiments:[{
      username:"eveosr",
      small_comment:"Shudve went vishal for banana leaf!"
    }, {
      username: "sitwsit",
      small_comment: "oh goodie! Always scratching my head on what to eat around KL Sentral. Now I don't need too anymore yaay!",
      username1: "chloeinspaces",
      small_comment1: "I'll save this for later. I need to visit you in Malaysia."
    }, {
     username: "spencerdouglass",
     small_comment: "I remember Jaslyn Cakes!!!",
     username1: "desmondkhr",
     small_comment1: "I  wana ko KL Sentral",
    }, {
      username: "jerryahb",
      small_comment: "Sunkee restaurant? I'll mark that down",
      username1: "p.moiw",
      small_comment1: "I want to go to anakbaba! As we go to kl in summer, we will use your niformation as a reference!",
    }],
  }]

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }
   

  const scrollToTop = () => window.scrollTo({top: 0, behavior: "smooth"});

  const childElements = stories.map((element, index)=>{
    return (
      <Link
        to={{
          pathname: `/success-stories-details/${element.name}`,
          state: element
        }}
        className={styles["text-element"]}
        onMouseEnter={()=>{
          setIsShowImage(index)
        }}
        onMouseLeave={()=>{
          setIsShowImage(undefined)
        }}
        onClick={() =>{
          scrollToTop();
        }}
      >
        
        <div className={styles.txt}>{element.name} 
          <span className={styles["num"]}>{(index + 1) <= 9 ? "0" + (index + 1) : index + 1}</span>
        </div>
          
        {
          (isShowImageIndex == index) &&
          <Animated className={styles.imgan} animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDuration={500}>
            <div className={styles.image}>
              <img src={element.image} />
            </div>
          </Animated>
        }
      </Link>
    );
  });

  return (
    <Row>
      <Col> 
        <Row>
          <Col>
          {/* <ScrollToTopOnMount /> */}
            <h2 className={styles["banner-title"]}>{title}</h2>
            <div className={styles["banner-img"]}>
              <img src={bannerImage}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={styles.cluster} xs={12}>
            {childElements}
          </Col>
        </Row>
      </Col>

    </Row>
  );
}

export default StoriesGrid;