import React from "react";
import Helmet from "react-helmet";

import StoriesGrid from "../common-components/StoriesGrid";
import styles from "./SuccessStories.module.scss"

const SuccessStories = () =>{
  return (
    <div className={styles.main}>
      <Helmet>
      <title>KOLS.ASIA Influencer Marketing Agency | Success Stories</title>
        <meta name="description" content="KOLS.ASIA | success stories"/>
        <meta name="keywords" content="KOLS.ASIA, influencer marketing agency success stories page"/>
        {/* FB open graph meta description */}
        <meta property="og:title" content="KOLS.ASIA | Success Stories" />
        <meta property="og:description" content="KOLS.ASIA success stories" />
      </Helmet>
      <StoriesGrid title={"success stories"}/>
    </div>
  );
}

export default SuccessStories;