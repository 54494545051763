import React, { useLayoutEffect, useEffect, useState } from "react";
import styles from "./Home.module.scss"
import { ParallaxBanner, Parallax, useController } from "react-scroll-parallax";
import { useMediaQuery } from 'react-responsive'
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import gql from 'graphql-tag';
import { useMutation} from '@apollo/react-hooks';
import  ModalDefault  from "../common-components/ModalDefault";

import {Animated} from "react-animated-css";
//@ts-ignore
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import Draggable from 'react-draggable';

import enabler1 from "../assets/home-slider/enabler1.png";
import enabler2 from "../assets/home-slider/enabler2.png";
import enabler3 from "../assets/home-slider/enabler3.png";
import enabler4 from "../assets/home-slider/enabler4.png";
import granular1 from "../assets/home-slider/granular1.png";
import granular2 from "../assets/home-slider/granular2.png";
import granular3 from "../assets/home-slider/granular3.png";
import granular4 from "../assets/home-slider/granular4.png";
import granular5 from "../assets/home-slider/granular5.png";

//eucerin
import eucerinmain from "../assets/eucerin/main.jpg";
import eucerin1 from "../assets/eucerin/1.jpg";
import eucerin2 from "../assets/eucerin/2.jpg";
import eucerin3 from "../assets/eucerin/3.jpg";
import eucerin4 from "../assets/eucerin/4.jpg";
import eucerin5 from "../assets/eucerin/5.jpg";
//lancome
import lancomemain from "../assets/lancome/main.jpg";
import lancome1 from "../assets/lancome/1.jpg";
import lancome2 from "../assets/lancome/2.jpg";
import lancome3 from "../assets/lancome/3.jpg";
import lancome4 from "../assets/lancome/4.jpg";
import lancome5 from "../assets/lancome/5.jpg";
import lancome6 from "../assets/lancome/6.jpg";
import lancome7 from "../assets/lancome/7.jpg";
import lancome8 from "../assets/lancome/8.jpg";
import lancome9 from "../assets/lancome/9.jpg";
import lancome10 from "../assets/lancome/10.jpg";
import lancome11 from "../assets/lancome/11.jpg";
//swatch
import swatchmain from "../assets/swatch/main.jpg";
import swatch1 from "../assets/swatch/1.jpg";
import swatch2 from "../assets/swatch/2.jpg";
import swatch3 from "../assets/swatch/3.jpg";
import swatch4 from "../assets/swatch/4.jpg";
import swatch5 from "../assets/swatch/5.jpg";
import swatch6 from "../assets/swatch/6.jpg";
import swatch7 from "../assets/swatch/7.jpg";
import swatch8 from "../assets/swatch/8.jpg";
//panasonic
import panasonicmain from "../assets/panasonic/main.png";
import panasonic1 from "../assets/panasonic/1.png";
import panasonic2 from "../assets/panasonic/2.png";
import panasonic3 from "../assets/panasonic/3.jpg";
import panasonic4 from "../assets/panasonic/4.jpg";

import Biotherm from "../assets/Biotherm.svg";
import Boost from "../assets/Boost.svg";
import Eucerin from "../assets/Eucerin.svg";
import Kiehl from "../assets/Kiehl.svg";
import Nivea from "../assets/Nivea.svg";
import Panasonic from "../assets/Panasonic.svg";
import Lancome from "../assets/Lancome.svg";
import Loreal from "../assets/Loreal.svg";
import Yves from "../assets/Yves_Saint_Laurent_Logo2.svg";
import Sunway from "../assets/sunway.svg";
import Girl from "../assets/Girl.png";
import EucerinImg from "../assets/Eucerin.png";
import LancomeImg from "../assets/Lancome.png";
import SwatchImg from "../assets/Swatch.png";
import PanasonicImg from "../assets/Panasonic.png";
import arrowRightImg from "../assets/arrow-right.png";
import arrowImg from "../assets/Arrow.png";
import ArrowRightBlack from "../assets/arrow_right.svg";
import StepByStep from "../assets/step-step.png";
import CarouselSlide from "./components/CarouselSlide";

import ClientLogo_PernordRicard from '../assets/client-logos/Pernord_Ricard.svg';
import ClientLogo_Nestle from '../assets/client-logos/Nestle.svg';
import ClientLogo_Celcom from '../assets/client-logos/Celcom.svg';
import ClientLogo_UMobile from '../assets/client-logos/Umobile.svg';
import ClientLogo_TuneProtect from '../assets/client-logos/Tune_Protect.svg';
import ClientLogo_UNIQLO from '../assets/client-logos/UNIQLO.svg';
import ClientLogo_SAMSUNG from '../assets/client-logos/SAMSUNG.svg';
import ClientLogo_HUAWEI from '../assets/client-logos/HUAWEI.svg';
import ClientLogo_VIVO from '../assets/client-logos/VIVO.svg';
import ClientLogo_TouchNGo from '../assets/client-logos/Touch_n_GO.svg';
import ClientLogo_CALTEX from '../assets/client-logos/CALTEX.svg';
import ClientLogo_Eucerin from '../assets/client-logos/Eucerin.svg';
import ClientLogo_Astro from '../assets/client-logos/Astro.svg';
import ClientLogo_Lazada from '../assets/client-logos/Lazada.svg';
import ClientLogo_ZALORA from '../assets/client-logos/ZALORA.svg';
import ClientLogo_OCBC_Bank from '../assets/client-logos/OCBC_Bank.svg';
import ClientLogo_BSN from '../assets/client-logos/BSN.svg';
import ClientLogo_KOSE from '../assets/client-logos/KOSE.svg';
import ClientLogo_LANEIGE from '../assets/client-logos/LANEIGE.svg';
import ClientLogo_HADALABO from '../assets/client-logos/HADALABO.svg';
import ClientLogo_DUTCH_LADY from '../assets/client-logos/DUTCH_LADY.svg';
import ClientLogo_L_OREAL from '../assets/client-logos/L_OREAL.svg';
import ClientLogo_Moet_Hennessy from '../assets/client-logos/Moet_Hennessy.svg';
import ClientLogo_Bio_essence from '../assets/client-logos/Bio_essence.svg';

const ParallaxCache = () => {
  const { parallaxController } = useController();

  useLayoutEffect(() => {
      const handler = () => parallaxController.update();
      window.addEventListener('load', handler);
      return () => window.removeEventListener('load', handler);
  }, [parallaxController]);

  return null;
};
const Home = () =>{
  const eucerinData = {
    id:10,
    name:"eucerin",
    image: eucerinmain,
    brief_description:"Eucerin ProAcne Solution",
    full_description:"Testimonial based campaign where the KOLs were asked to show a'before vs after' using the product for a week",
    campaign_period:"1 month",
    total_kols_engaged:"26 Mid Tier - Top Tier",
    deliverables:"IG multi-post",
    total_content:26,
    total_reach:1099793,
    total_engagement:87201,
    product_images:[eucerin1,eucerin2,eucerin3,eucerin4,eucerin5],
    sentiments:[{
      username:"ftinasreen_ @ntshafsl",
      small_comment:"try this",
      username1:"shaz_ezman",
      small_comment1:"Aira untuk scars okay ke ?"
    }, {
      username: "dicksontiw",
      small_comment: "Really good? I will try this"
    }, {
      username: "sni0409",
      small_comment: "Seres ah berkesan nak cuba ni",
      username1: "fariqh92",
      small_comment1: "A.I Clearing Treatment tu sumpah berkesan!"
    }],
  };

  const lancomeData = {
    id:3,
    name:"Lancome",
    image: lancomemain,
    brief_description:"Lancome Clarifique Dual essence launch",
    full_description:"To generate buzz on the new product launch, Lancome collaborated with top KOLs from the beauty scene on a 7-day challenge to showcase their transformations using the product",
    campaign_period:"2 months",
    total_kols_engaged:"15 Mid Tier - Top Tier",
    deliverables:"IG multi-post",
    total_content:15,
    total_reach:359357,
    total_engagement:46021,
    product_images:[lancome1,lancome2,lancome3, lancome4, lancome5, lancome6, lancome7, lancome8, lancome9 ,lancome10,lancome11
    ],
    sentiments:[{
      username:"shamieraosment",
      small_comment:"Wow lancome..best tu..",
      username1: "mekonie_",
      small_comment1:"Best tak?",
      usernameMain: "frhdafri",
      comment: "@mekonie_ besttt! Nnt hujung bulan ni faa share before and after photo. Bykk nampak beza"
    }, {
      username: "my_soultravels",
      small_comment: "I love Lancome products. This one looks like a great product too."
    }, {
      username: "iamakhilsharma",
      small_comment: "That's really cool. Sharing it with my wife"
    }, {
      username: "nadiasamjohari",
      small_comment: "Best tak.. Belum try lagi jenama ni",
      usernameMain: "frhdafri",
      comment: "@nadiasamjohari best kak nnt kite share perubahan guna ni. Byk juga beza"
    }],
  };

  const swatchData = {
    id:9,
    name:"Swatch",
    image: swatchmain,
    brief_description:"Swatch Big Bold",
    full_description:"In collaboration with Swatch, these fashion and lifestyle based KOLs were chosen to create lifestyle images featuring the Big Bold range",
    campaign_period:"2 months",
    total_kols_engaged:"10 Mid Tier - Top Tier",
    deliverables:"IG photos",
    total_content:20,
    total_reach:293309,
    total_engagement:58128,
    product_images:[swatch1, swatch2, swatch3,swatch4,swatch5,swatch6,swatch7,swatch8],
    sentiments:[{
      username:"shahad_abu_sharar",
      small_comment:"Wow I like this wristwatch Nice pic",
      username1: "mr_arrogant84",
      small_comment1: "Ok jam ni lawa!!!!!!"
    }, {
      username: "himel2010",
      small_comment: "Really love the watch brother. It looks spectacular"
    }, {
      username: "mrsyayaz",
      small_comment: "Wow swatch! design baru",
      usernameMain: "shukrimarch",
      comment: "@mrsyayaz yeah... #swatchbigbold beli lah utk shuben"
    }, {
      username: "aidahusnina_",
      small_comment: "Wahhhhhh cantik laaa jam dia",
      usernameMain: "shukrimarch",
      comment: "@aidahusnina_ beli lah utk islah haahah"
    }],
  };

  const panasonicbeautyData = {
    id:6,
    name:"Panasonic Beauty",
    image: panasonicmain,
    brief_description:"Panasonic x Kuala Lumpur Fashion Week",
    full_description:"Panasonic invited a group of A-List influencers to be a part of their collaboration with KLFW. They all went home with the iconic Panasonic Nanoe N98 Hair Dryer and created how to videos featureing the product's different functions",
    campaign_period:"1 month",
    total_kols_engaged:"8 A-List",
    deliverables:"Event coverage + IG photos + IG videos",
    total_content:24,
    total_reach:916274,
    total_engagement:177805,
    product_images:[panasonic1, panasonic2, panasonic3, panasonic4 ],
    sentiments:[{
      username:"woonsoonteng",
      small_comment:"Yes it's a very good hair dryer I have been using for more than 30 years"
    }, {
      username: "marionchin",
      small_comment: "@nicckane @yishyuenn Need this holy grail cuz my nothing can save my hay-like hairexcept this!"
    }, {
      username: "yan_tattooist",
      small_comment: "I want the tok kong hair dryer because so ngam my hair dryer sudah rosak. So ngam kittie doing giveaway! Just trying my luck! @jasonwong_tattooist @yying.tattoo__"
    }],
  };
  const enabler_images = [enabler1, enabler2, enabler3, enabler4];
  const granular_images = [granular1, granular2, granular3, granular4, granular5];

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  const isParallaxDisabled = isTabletOrMobileDevice ? true : false;
  
  const [isModal, setShowModal] = useState(false);
  const [isError, setShowError] = useState(false);

  const scrollToTop = () => {
    if(!isModal) {
      window.scrollTo({top: 0, behavior: "smooth"}); 
    }
  }

  const handleFollowMouse = ()=>{
  //@ts-ignore
    let arrowWrapper = document.querySelector('body');
    if(!!arrowWrapper && arrowWrapper !== null){
      arrowWrapper.addEventListener('mousemove', (e) => {
        //@ts-ignore
        let arrow = document.querySelector('#arrow-wrapper');
        if(!!arrow && arrow !== null){
          
          let mouseX = (arrow.getBoundingClientRect().left); 
          let mouseY = (arrow.getBoundingClientRect().top);
          //@ts-ignore

          let radianDegrees = Math.atan2(e.pageX - mouseX, e.pageY - mouseY);
          let rotationDegrees = (radianDegrees * (180/ Math.PI) * -1) + 180;
          //@ts-ignore
          arrow.style.transform = `rotate(${rotationDegrees}deg)`
        }
      });
    }
  }

  useEffect(()=>{
    setTimeout(() => {
      handleFollowMouse()
    }, 0);

  },[]);

  const SEND_BOOKING = gql`
    mutation insert_demo_booking($budget: String, $companyName: String, $email: String, $firstName: String,
      $lastName: String, $phoneNumber: String){
      insert_demo_booking(objects:{
        budget: $budget
        companyName: $companyName
        email: $email
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
      }){
        affected_rows
      }
    } 
  `;

  const openModal = () => {
    setShowModal(true);
  }

  const [insert_demo_booking_one] = useMutation(SEND_BOOKING, {
    onCompleted: openModal
  });
 
  let budget: any, companyName: any, email: any, firstName: any, lastName: any, phoneNumber: any;

  const postSend = ({budget, companyName, email, firstName, lastName, phoneNumber}: any) => {
    if((budget && budget != '') && (companyName && companyName !='') && (email && email !='') && (firstName && firstName !='')
    && (lastName && lastName !='') && (phoneNumber && phoneNumber !='') ) {
      setShowError(false);
      insert_demo_booking_one({
        variables: {
          budget: budget,
          companyName: companyName,
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber
        }
      });
    } else {
      setShowError(true);
    }
   
  }


  return (
   
    <main role="main" className="container-fluid" onLoad={scrollToTop}>
      <Helmet>
        <title>KOLS.ASIA Influencer Marketing Agency | About Us</title>
        <meta name="description" content="KOLS.ASIA is a full service influencer marketing agency | about us"/>
        <meta name="keywords" content="KOLS.ASIA, influencer marketing agency about us page"/>
        {/* FB open graph meta description */}
        <meta property="og:title" content="KOLS.ASIA | Influencer Marketing Agency - Social Meda Influencer about us" />
        <meta property="og:description" content="KOLS.ASIA about us social media" />
      </Helmet>
      <div className={styles.wrapper}>
        {
          isModal &&
          <ModalDefault updateShowModal={setShowModal} showModal={isModal}/>
        }
        
        <Row id="top" className={styles.hero}>
          <Col id="top1">
            <Row className={styles["row-container"]}>
              <Col className={styles.holder}>
                
                <Parallax
                    y={[-25, 50]}
                    //x={[1,1]}
                    className={styles["text"]}
                    disabled={isParallaxDisabled}
                  >
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDuration={1000}>
                      <h1>FULL-SERVICE</h1>
                    </Animated>
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDuration={1300}>
                      <h1>INFLUENCER</h1>
                    </Animated>
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDuration={1500}>
                      <h1>MARKETING</h1>
                    </Animated>
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDuration={1800}>
                      <h1>AGENCY</h1>
                    </Animated>
                    <div id="arrow-wrapper" className={styles["arrow-wrapper"]}>
                      <img  className="arrow-img" src={arrowImg}/>
                </div>
                </Parallax>

                <Parallax 
                  className={styles["small-img"]}
                  y={[-30,50]}
                  //x={[-13,-13]}
                    disabled={isParallaxDisabled}
                >
                <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDuration={1800}>

                  <div className={`${styles["img-in-div"]} ${styles["hero-small-img"]}`}>

                  </div>
                </Animated>
                <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDuration={2000}>
                  <span className={styles["caption"]}>Powered by big data</span>
                </Animated>
                </Parallax>
              </Col>
            </Row>

            <Row>
              <Col>
                <Animated animationIn="fadeInUpBig" animationOut="fadeOut" isVisible={true} animationInDuration={1800}>

                  <Parallax
                      y={[0,0]}
                      //y={["-600px","6px"]}
                      //x={[1,1]}
                      className={styles["bigger-img"]}
                      disabled={isParallaxDisabled}
                    >
                      <div className={`${styles["img-in-div"]} ${styles["hero-center-img"]}`}>
                        <span className={styles["caption"]}>Powered by big data</span>
                      </div>
                    </Parallax>
                </Animated>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={styles.hero2}>
          <Col>
            
            <div className={styles["sec-row-image"]}>
              <div className={styles["sec-img"]}>

              </div>
                <div className={`${styles["caption"]} ${styles["text-white"]}`}>
                  oh, and driven by awesome human beings and a mission to empower creative talents
                </div>
            </div>
            <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2000}>

              <div className={styles["sec-row-text"]}>
                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                    <span className={styles["overlay-text"]}>and best-in-class</span>
                  </AnimatedOnScroll>

                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                    <span className={styles["overlay-text"]}>proprietary partner</span>
                  </AnimatedOnScroll>
                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1500}>
                    <span className={styles["overlay-text"]}>technology, to</span>
                  </AnimatedOnScroll>
                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2000}>
                    <span className={styles["overlay-text"]}>unlock valuable</span>
                  </AnimatedOnScroll>
                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2500}>
                    <span className={styles["overlay-text"]}>growth equity </span>
                  </AnimatedOnScroll>
                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={3000}>
                    <span className={styles["overlay-text"]}>
                    for brands.</span>
                  </AnimatedOnScroll>     
                <div className={styles.caption}>
                  oh, and driven by awesome human beings and a mission to empower creative talents
                </div>
              </div>
            </AnimatedOnScroll>
          </Col>
        </Row>

        <Row className={styles.hero3}>
          <Col>
          <AnimatedOnScroll animationIn="pulse" isVisible={true} animationInDuration={500}>
            <div className={styles["third-row-image"]}>
              <div className={styles["third-img"]}>

              </div>
              <div className={styles.caption}>
                with an ethos of altruism, passion, growth, empathy and integrity
              </div>
            </div>
          </AnimatedOnScroll>
          </Col>
        </Row>


        <Row>
          <Col>
            <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={2000}>
              <div className={styles["solid-line"]}/>
            </AnimatedOnScroll>
          </Col>
        </Row>

        <Row className={styles["guess-work"]}>
          <Col>
          <Row className={styles["offset-row"]}>
            <Col xs="12" lg="6">
              <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                <span className={styles.jumbo}>
                  No more
                </span>
              </AnimatedOnScroll>
              <br/>
              <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1500}>
                <span className={styles.jumbo}>
                  guesswork.
                </span>
              </AnimatedOnScroll>
            </Col>
            <Col>
            <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2500}>
              <span className={styles.desc}>
                Influencer marketing powered by big data that enables precise audience targeting instead of guesswork, PLUS pre-campaign ROI guarantee, AND post-campaign granular insights

              </span>
            </AnimatedOnScroll>

            </Col>
          </Row>
          <Row>
            <Col>
            <AnimatedOnScroll animationIn="fadeIn" isVisible={true} animationInDuration={2000}>
                <div className={styles.banner}>

                </div>
            </AnimatedOnScroll>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className={styles["desc-under"]}>
                Influencer marketing powered by big data that enables precise audience targeting instead of guesswork, PLUS pre-campaign ROI guarantee, AND post-campaign granular insights

              </span>
            </Col>
          </Row>
          </Col>
        </Row>

        <Row className={styles.collage}>
          <Col>
            <Row className={styles["offset-row"]}>
              <Col lg="12">
                <div className={styles["text-holder"]}>
                  <div className={styles["profiles-stats"]}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>

                      <span>
                        12 million profiles
                      </span>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1500}>
                      <span>
                        across 100+
                      </span>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2000}>
                      <span>
                        countries
                      </span>
                    </AnimatedOnScroll>
                  </div>
                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2500}>
                    {/* <span className={styles.caption}>
                      A massive KOL database powered by specialised data mining tech that has aggregated over 12 million KOL profiles worldwide in our database.
                    </span> */}
                  </AnimatedOnScroll>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={5}>
                <div className={styles["top-left-collage-holder"]}>
                  <Draggable handle="article">
                    <article>
                      <AnimatedOnScroll animationIn="fadeInBottomRight" isVisible={true} animationInDuration={1000}>
                        <div className={styles["top-image"]}>

                        </div>
                      </AnimatedOnScroll>
                    </article>
                  </Draggable>

                
                  <Draggable handle="article">
                    <article>
                      <AnimatedOnScroll animationIn="fadeInBottomRight" isVisible={true} animationInDuration={1500}>
                        <div className={styles["bottom-image"]}>

                        </div>
                      </AnimatedOnScroll>
                    </article>
                  </Draggable>

                </div>
              </Col>
              <Col xs={12} sm={12} md={12}  lg={1}>
                {/* <span className={styles["profiles-caption-2"]}>
                  A massive KOL database powered by specialised data mining tech that has aggregated over 12 million KOL profiles worldwide in our database.
                </span> */}
              </Col>
              <Col xs={12} sm={12} md={12}  lg={6}>
                
                <AnimatedOnScroll animationIn="fadeInBottomLeft" isVisible={true} animationInDuration={1000}>
                  <div className={styles["top-right-collage-holder"]}>
                  <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2500}>
                    <span className={styles.caption}>
                      A massive KOL database powered by specialised data mining tech that has aggregated over 12 million KOL profiles worldwide in our database.
                    </span>
                  </AnimatedOnScroll>
                    <Draggable handle="article">
                      <article>
                        <div className={styles["top-image"]}>

                        </div>
                      </article>
                    </Draggable>
                    <Draggable handle="article">
                      <article>
                        <div className={styles["bottom-image"]}>

                        </div>
                      </article>
                    </Draggable>
                  </div>
                </AnimatedOnScroll>
              </Col>
            </Row>
            <Row className={styles.hero}>
              <Col>
              <Draggable handle="article">
                <article>
                  <AnimatedOnScroll animationIn="fadeInTopRight" isVisible={true} animationInDuration={2000}>
                    <div className={styles["collage-bottom-left"]}>

                    </div>
                  </AnimatedOnScroll>
                </article>
              </Draggable>
              </Col>
              <Col>

              <Draggable handle="article">
                <article>
                  <div className={styles["collage-bottom"]}>

                  </div>
                </article>
              </Draggable>
              <Draggable handle="article">
                <article>
                  <AnimatedOnScroll animationIn="fadeInTopLeft" isVisible={true} animationInDuration={2000}>
                    <div className={styles["collage-bottom-right"]}>

                    </div>
                  </AnimatedOnScroll>
                </article>
              </Draggable>
              </Col>
              <Col>

              </Col>
            </Row>
          </Col>

        </Row>

        <Row className={styles.charts}>
          <Col xs={12} sm={12} md={12} lg={6}>
            <h4>technology as enabler</h4>
            <p>
              Using elaborate data profiles, with over 100 million data points and 30,000+ topic categories, and #hashtags searches, we bring an advanced matchmaker between KOL and client, evaluating from demographics, psychographics, and geographic, to content and brand data points

            </p>
            <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
              <CarouselSlide images={enabler_images}/>
            </AnimatedOnScroll>
            
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <h4>advanced granular campaign insights</h4>
            <p>
              We’re against vanity metrics, and focus on meaningful actionable ROI analysis including: campaign economics, audience demographics data, content performance analysis.
            </p>
            <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
              <CarouselSlide images={granular_images}/>
            </AnimatedOnScroll>
          </Col>
        </Row>
      </div>
      <div className={`${styles.wrapper} ${styles["light-bg"]}`}> 
        <Row className={styles.why}>
          <Col>
            <Row>
              <Col>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <h1>why us?</h1>
                </AnimatedOnScroll>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                  <h2>01</h2>
                  <h5>Ability to activate Macro- to Micro-, and even Nano-influencers at scale</h5>
                  <p>
                    With big data tech, we have perfected a workflow for activating influencers with passionate followings in mass, irregardless of their tier. We routinely manage hundreds of influencers concurrently for our clients.
                  </p>
                </AnimatedOnScroll>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1500}>
                  <h2>02</h2>
                  <h5>Influencer agnostic approach</h5>
                  <p>
                    Because we do not operate as a talent agency, nor are we constrained by influencer sign ups, we do not need to push a roster of influencer profiles that do not fit your target audience or objectives. 
                  </p>
                  <p>
                    Our influencer recommendations are justified through a proprietary tech stack. We analyze the audiences of influencers, ensuring their audiences line up with your target as closely as possible.
                  </p>
                </AnimatedOnScroll>
              
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={2000}>
                  <h2>03</h2>
                  <h5>Measuring the right ROI - SMV</h5>
                  <p>
                    One of the most critical KPIs for brands to be tracking now is Social Media Value. SMV (also known as Earned Media Value), defined as the amount of free media your brand earns that it would have otherwise paid for with advertising.
                  </p>
                  <p>
                    Thanks to an integration with a.EMV index, our platform calculates SMV instantly, in real time, specific to industry verticals, so you can see ROI for a campaign as a whole, and for specific influencers. We’ve also broken it down between posts and stories, so you can easily identify which channel yielded better value.
                  </p>
                </AnimatedOnScroll>
              </Col>
            </Row>
            <Row>
              <Col  xs={12} sm={4} md={4} lg={4}>
              </Col>
              <Col  xs={12} sm={4} md={4} lg={4}>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <h2>04</h2>
                  <h5>Guaranteed Brand Safety</h5>
                  <p>
                    Our built-in anti fraud suite detects unusual jumps in follower counts over time, as well as other signals of fraud or inactivity, into a simple audience health score for evaluation.
                  </p>
                  <p>
                    We can even go a step further to run legal background checks on KOL’s historical posts to identify racism, nudity, and more, to confirm there are no hidden truths we should know about before a formal partnership is established.
                  </p>
                </AnimatedOnScroll>
              </Col>
              <Col  xs={12} sm={4} md={4} lg={4}>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                  <h2>05</h2>
                  <h5>Zero agency fee</h5>
                  <p>
                    With our unique revenue share model with creators, we make it super easy for your decision making when it comes to budgeting.
                  </p>
                </AnimatedOnScroll>
              </Col>
            </Row>
        </Col>
        </Row>
        
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
        
        <Row className={styles.success}>
          <Col>
            <Row>
              <Col>
              <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                <h1>
                  Success
                </h1>
              </AnimatedOnScroll>
              <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                <h1>
                  Stories
                </h1>
              </AnimatedOnScroll>
                
              </Col>
            </Row>
            <Row>
              <Col  xs={12} sm={12} md={12} lg={6}>
              <AnimatedOnScroll animationIn="fadeIn" isVisible={true} animationInDuration={1000}>
                <div className={styles["left-img-holder"]}>
                  <div className={styles["left-img"]}>
                    <img src={EucerinImg}/>
                  </div>
                  <div className={styles["caption"]}>
                    <Row>
                      <Col  xs={12} sm={12} md={12} lg={6}>
                        <Link
                          to={{
                            pathname: `/success-stories-details/eucerin`,
                            state: eucerinData
                          }}
                          onClick={() =>{
                            scrollToTop();
                          }}
                          className={styles["text-element"]}
                        >
                          <span className={styles.name}>Eucerin </span>
                        </Link>
                      </Col>
                      <Col  xs={12} sm={12} md={12} lg={6}>
                        <span>
                          Testimonial based campaign where the KOLs were asked to show a'before vs after' using the product for a week.
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </AnimatedOnScroll>
              </Col>
              <Col  xs={12} sm={12} md={12} lg={6}>
              <AnimatedOnScroll animationIn="fadeIn" isVisible={true} animationInDuration={1000}>
                <div className={styles["right-img-holder"]}>

                  <div className={`${styles["caption"]} ${styles["hidemobile"]}`}>
                    <Row>
                      <Col  xs={12} sm={12} md={12} lg={6}>
                        <Link
                          to={{
                            pathname: `/success-stories-details/lancome`,
                            state: lancomeData
                          }}
                          onClick={() =>{
                            scrollToTop();
                          }}
                          className={styles["text-element"]}
                        >
                          <span className={styles.name}> lancome</span>
                        </Link>
                      </Col>
                      <Col  xs={12} sm={12} md={12} lg={6}>
                        <span>
                          To generate buzz on the new product launch, Lancome collaborated with top KOLs from the beauty scene on a 7-day challenge to showcase their transformations using the product.
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className={styles["right-img"]}>
                    <img src={LancomeImg}/>
                  </div>

                  <div className={`${styles["caption"]} ${styles["hidelarge"]}`}>
                    <Row>
                      <Col  xs={12} sm={12} md={12} lg={6}>
                        <Link
                          to={{
                            pathname: `/success-stories-details/lancome`,
                            state: lancomeData
                          }}
                          onClick={() =>{
                            scrollToTop();
                          }}
                          className={styles["text-element"]}
                        >
                          <span className={styles.name}> lancome</span>
                        </Link>
                      </Col>
                      <Col  xs={12} sm={12} md={12} lg={6}>
                        <span>
                          To generate buzz on the new product launch, Lancome collaborated with top KOLs from the beauty scene on a 7-day challenge to showcase their transformations using the product.
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
                </AnimatedOnScroll>
              </Col>
            </Row>
            <Row className={styles.swatch}>
              <Col  xs={12} sm={12} md={12} lg={6}>
                <AnimatedOnScroll animationIn="fadeIn" isVisible={true} animationInDuration={1000}>
                  <div className={styles["center-img"]}>
                    <img src={SwatchImg}/>
                  </div>
                </AnimatedOnScroll>
              </Col>
              <Col  xs={12} sm={12} md={12} lg={6}>
                <div className={styles.caption}>
                  <Link
                    to={{
                      pathname: `/success-stories-details/swatch`,
                      state: swatchData
                    }}
                    onClick={() =>{
                      scrollToTop();
                    }}
                    className={styles["text-element"]}
                  >
                    <span className={styles.name}> swatch </span>
                  </Link>
                  <br/>
                  <span>
                    In collaboration with Swatch, these fashion and lifestyle based KOLs were chosen to create lifestyle images featuring the Big Bold range.
                  </span>
                </div>
              </Col>
            </Row>

            <Row className={styles.panasonic}> 
              <Col lg={5}>
                <div className={`${styles.caption} ${styles.captionTop}`}>
                  <Link
                      to={{
                        pathname: `/success-stories-details/Panasonic Beauty`,
                        state: panasonicbeautyData
                      }}
                      onClick={() =>{
                        scrollToTop();
                      }}
                      className={styles["text-element"]}
                    >
                    <span className={styles.name}> panasonic </span>
                  </Link>
                  <br/>
                  <span>
                    Panasonic invited a group of A-List influencers to be a part of their collaboration with KLFW. They all went home with the iconic Panasonic Nanoe N98 Hair Dryer and created how to videos featureing the product's different functions.
                  </span>
                </div>
              </Col>
              
              <Col lg={7}>
                  <AnimatedOnScroll animationIn="fadeIn" isVisible={true} animationInDuration={1000}>

                  <div className={styles["panasonic-img"]}>
                    <img src={PanasonicImg}/>
                  </div>
                  <div className={`${styles.caption} ${styles.captionBottom}`}>
                    <Link
                      to={{
                        pathname: `/success-stories-details/Panasonic Beauty`,
                        state: swatchData
                      }}
                      onClick={() =>{
                        scrollToTop();
                      }}
                      className={styles["text-element"]}
                    >
                      <span className={styles.name}> panasonic </span>
                    </Link>
                    <br/>
                    <span>
                      Panasonic invited a group of A-List influencers to be a part of their collaboration with KLFW. They all went home with the iconic Panasonic Nanoe N98 Hair Dryer and created how to videos featureing the product's different functions.
                    </span>
                  </div>
                </AnimatedOnScroll>

             
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                  <Link
                    to={{
                      pathname: `/success-stories`,
                    }}
                    onClick={() =>{
                      scrollToTop();
                    }}
                  >
                    view more
                    <div className={styles.arrowRight}>
                      <img src={ArrowRightBlack}/>
                    </div>
                  </Link>
                </AnimatedOnScroll>
              </Col>

            </Row>
          </Col>
        </Row>
      </div>
      <div className={styles.wrapper}>
     
        <Row>
          <Col>
            <Row className={styles.clients}>
              
              <Col  xs={12} lg={6}>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <h2 className={styles.desktopHead}>
                  Our  
                  </h2>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>

                  <h2 className={styles.desktopHead}>
                    clients
                  </h2>
                </AnimatedOnScroll>
                <h2 className={styles.mobileHead}>
                  Our clients
                </h2>
              </Col>
              {/* <Col  xs={12} lg={6}>
                <Row>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                      <div className={styles["img-client"]}>
                        <img src={Loreal}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                      <div className={styles["img-client"]}>
                        <img src={Nivea}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                </Row>

                <Row>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                      <div className={styles["img-client"]}>
                        <img src={Kiehl}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                      <div className={styles["img-client"]}>
                        <img src={Biotherm}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                </Row>

                <Row>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                      <div className={styles["img-client"]}>
                        <img src={Lancome}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                      <div className={styles["img-client"]}>
                        <img src={Panasonic}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                </Row>


                <Row>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                      <div className={styles["img-client"]}>
                        <img src={Yves}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                      <div className={styles["img-client"]}>
                        <img src={Eucerin}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                </Row>


                <Row>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                      <div className={styles["img-client"]}>
                        <img src={Sunway}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                  <Col className={styles.clientCol}>
                    <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                      <div className={styles["img-client"]}>
                        <img src={Boost}/>
                      </div>
                    </AnimatedOnScroll>
                  </Col>
                </Row>
              
              </Col> */}
              <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: 100, justifyContent: 'space-between'}}>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_PernordRicard}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_Nestle}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_Celcom}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_UMobile}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_TuneProtect}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_UNIQLO}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_SAMSUNG}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_HUAWEI}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_VIVO}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_TouchNGo}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_CALTEX}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_Eucerin}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_Astro}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_Lazada}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_ZALORA}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_OCBC_Bank}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_BSN}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_KOSE}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_LANEIGE}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_HADALABO}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_DUTCH_LADY}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_L_OREAL}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_Moet_Hennessy}/>
                  </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                  <div className={styles["img-client"]}>
                    <img src={ClientLogo_Bio_essence}/>
                  </div>
                </AnimatedOnScroll>
              </div>
            </Row>


            <Row className={styles.process}>
              <Col>
                 <Row>
                    <Col xs={12} sm={12} md={12} lg={5}>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7}>
                      <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={500}>
                        <h2>
                          Our 
                          <div className={styles.step}>
                            <img src={StepByStep}/>
                          </div>
                        </h2>
                      </AnimatedOnScroll>
                      <AnimatedOnScroll animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
                        <h2 className={styles.Process}>
                          process 
                        </h2>
                      </AnimatedOnScroll>
                    </Col>
                 </Row>
                 <Row>
                   <Col xs={12} lg={5}>
                    <img src={Girl} className={styles["process-img"]}/>
                   </Col>
                   <Col xs={12} lg={7}>
                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll>

                      <div className={styles["text-wrapper"]}>
                        <span className={styles.txt}>
                          campaign objectives
                        </span>
                        <span  className={styles.num}>
                          01
                        </span>
                      </div>

                      <div/>
                    </div>

                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll>
                      <div className={styles["text-wrapper"]}>
                        <span className={styles.txt}>
                          Audience targeting
                        </span>
                        <span  className={styles.num}>
                          02
                        </span>
                      </div>
                      <div/>
                    </div>


                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll>
                        <div className={styles["text-wrapper"]}>
                          <span className={styles.txt}>
                            Strategy development 
                          </span>
                          <span  className={styles.num}>
                            03
                          </span>
                        </div>
                      <div/>
                    </div>
                    
                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll> 
                        <div className={styles["text-wrapper"]}>
                          <span className={styles.txt}>
                            Creative specifications 
                          </span>
                          <span  className={styles.num}>
                            04
                          </span>
                        </div>
                      <div/>
                    </div>
                    
                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll> 
                      <div className={styles["text-wrapper"]}>
                        <span className={styles.txt}>
                          Outreach & execution
                        </span>
                        <span  className={styles.num}>
                          05
                        </span>
                      </div>
                      <div/>
                    </div>

                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll> 
                      <div className={styles["text-wrapper"]}>
                        <span className={styles.txt}>
                          Progress monitoring
                        </span>
                        <span  className={styles.num}>
                          06
                        </span>
                      </div>
                      <div/>
                    </div>

                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll> 
                      <div className={styles["text-wrapper"]}>
                        <span className={styles.txt}>
                          Performance analysis
                        </span>
                        <span  className={styles.num}>
                          07
                        </span>
                      </div>
                      <div/>
                    </div>
                    <div className={styles["process-item"]}>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll> 
                      <div className={styles["text-wrapper"]}>
                        <span className={styles.txt}>
                          Recommendations & Repeat
                        </span>
                        <span  className={styles.num}>
                          08
                        </span>
                      </div>
                      <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                        <div className={styles.border}/>
                      </AnimatedOnScroll> 
                    </div>
                   </Col>
                 </Row>
              </Col>
            </Row>

            <Row className={styles.book}>
              <Col>
                <Row>
                  <Col xs={12} lg={5}></Col>
                  <Col xs={12} lg={7}>
                    <h2>
                      <span>book</span>
                      <span className={styles.small}>Let us show you how to maximize your marketing ROI to drive brand equity growth</span>
                    </h2>
                    <h2>
                      a virtual demo
                    </h2>
                    <span className={styles.smallUnder}>Let us show you how to maximize your marketing ROI to drive brand equity growth</span>

                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={5}>
                  </Col>
                  <Col xs={12} lg={7}>
                    <form id="book-form" action="">
                      <Row>
                        <Col  xs={12} lg={6}>
                          <div className="input-group">
                            <input ref={ value => firstName = value} type="text" required/>
                            <span className="highlight"></span>
                            <label>first name</label>

                            <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                              <div className="input-border"/>
                            </AnimatedOnScroll> 
                          </div>
                        </Col>
                        <Col  xs={12} lg={6}>
                          <div className="input-group">
                            <input ref={ value => lastName = value} type="text" required/>
                            <span className="highlight"></span>
                            <label>last name</label>
                            <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                              <div className="input-border"/>
                            </AnimatedOnScroll> 
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col  xs={12} lg={6}>
                          <div className="input-group">
                            <input ref={ value => phoneNumber = value} type="text" required/>
                            <span className="highlight"></span>
                            <label>phone</label>
                            <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                              <div className="input-border"/>
                            </AnimatedOnScroll> 
                          </div>
                        </Col>
                        <Col  xs={12} lg={6}>
                          <div className="input-group">
                            <input ref={ value => companyName = value} type="text" required/>
                            <span className="highlight"></span>
                            <label>company</label>
                            <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                              <div className="input-border"/>
                            </AnimatedOnScroll> 
                          </div>
                        </Col>
                      </Row>


                      <Row>
                        <Col  xs={12} lg={6}>
                          <div className="input-group">
                            <input ref={ value => email = value} type="text" required/>
                            <span className="highlight"></span>
                            <label>email</label>
                            <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                              <div className="input-border"/>
                            </AnimatedOnScroll> 
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col  xs={12} lg={6}>
                          <div className="input-group">
                            <input ref={ value => budget = value} type="text" required/>
                            <span className="highlight"></span>
                            <label>budget indication</label>
                            <AnimatedOnScroll animationIn="fadeInLeft" isVisible={true} animationInDuration={1000}>
                              <div className="input-border"/>
                            </AnimatedOnScroll> 
                          </div>
                        </Col>

                        <Col  xs={12} lg={6}>
                          <div className={styles["contact-btn"]}>
                            <button onClick={ e => {
                              e.preventDefault();
                              postSend({
                                'budget': budget.value,
                                'companyName': companyName.value,
                                'email': email.value,
                                'firstName': firstName.value,
                                'lastName': lastName.value,
                                'phoneNumber': phoneNumber.value
                              });
                              
                            }}><span>Send</span> <i><img src={arrowRightImg}/></i></button>
                          </div>
                        </Col>
                      </Row>

                      {
                        isError &&
                          <span  className={styles["errorMsg"]}>Please fill up all fields.</span>
                      }
                    
                    </form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </main>
  );
}

export default Home