import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, useLocation
} from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col } from "react-bootstrap"
import Home from "./Home";
import About from "./About";
import JoinUs from "./JoinUs";
import SuccessStories from "./SuccessStories";
import logo from "./assets/logo_white.png"
import menu from "./assets/menu.png"
import "./styles/base.scss"
import Footer from "./common-components/Foooter";
import SuccessStoriesInner from "./SuccessStoriesInner";
import LandingPage from "./LandingPage";
import TermPolicy from "./TermPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import LandingPageMobile from "./LandingPageMobile/LandingPageMobile";
import PrivacyPolicyMobile from "./PrivacyPolicyMobile";
import TermPolicyMobile from "./TermPolicyMobile";

export default function App() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Navbar collapseOnSelect expand="lg"  variant="dark">
            <Navbar.Brand href="/">
              <div className="logo">
                <img src={logo}/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={()=>setIsOpen(isOpen === true ? false : true)}>
              {
                isOpen &&

                <div className="main-menu-icon-close">
                </div>
                ||
                <div className="main-menu-icon">
                </div>
              }
            </Navbar.Toggle>
            
            <Navbar.Collapse className={"fadeInDown"} id="responsive-navbar-nav">
              <Nav className="mr-auto">
            
              </Nav>
              <Nav>
                <Nav.Link href="/"><span className="nav-num">1/</span>About Us</Nav.Link>
                <Nav.Link href="/success-stories"><span className="nav-num">2/</span>Success Stories</Nav.Link>
                <Nav.Link className={"joinuslink"} href="/join-us"><span className="nav-num">3/</span>Join Us</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Home />
          <Footer/>
        </Route>
        <Route exact path="/tos">
          <div className="show-on-desktop hide-on-mobile">
            <TermPolicy />
          </div>
          <div className="show-on-mobile hide-on-desktop">
            <TermPolicyMobile />
          </div>
        </Route>
        <Route exact path="/privacy">
          <div className="show-on-desktop hide-on-mobile">
            <PrivacyPolicy />
          </div>
          <div className="show-on-mobile hide-on-desktop">
            <PrivacyPolicyMobile />
          </div>
        </Route>
        <Route exact path="/get">
          <div className="show-on-desktop hide-on-mobile">
            <LandingPage />
          </div>
          <div className="show-on-mobile hide-on-desktop">
           <LandingPageMobile />
          </div>
        </Route>
        <Route path="/about">
          <Navbar collapseOnSelect expand="lg"  variant="dark">
            <Navbar.Brand href="/">
              <div className="logo">
                <img src={logo}/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={()=>setIsOpen(isOpen === true ? false : true)}>
              {
                isOpen &&

                <div className="main-menu-icon-close">
                </div>
                ||
                <div className="main-menu-icon">
                </div>
              }
            </Navbar.Toggle>
            
            <Navbar.Collapse className={"fadeInDown"} id="responsive-navbar-nav">
              <Nav className="mr-auto">
            
              </Nav>
              <Nav>
                <Nav.Link href="/"><span className="nav-num">1/</span>About Us</Nav.Link>
                <Nav.Link href="/success-stories"><span className="nav-num">2/</span>Success Stories</Nav.Link>
                <Nav.Link className={"joinuslink"} href="/join-us"><span className="nav-num">3/</span>Join Us</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <About />
          <Footer/>
        </Route>
        <Route path="/join-us">
          <Navbar collapseOnSelect expand="lg"  variant="dark">
            <Navbar.Brand href="/">
              <div className="logo">
                <img src={logo}/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={()=>setIsOpen(isOpen === true ? false : true)}>
              {
                isOpen &&

                <div className="main-menu-icon-close">
                </div>
                ||
                <div className="main-menu-icon">
                </div>
              }
            </Navbar.Toggle>
            
            <Navbar.Collapse className={"fadeInDown"} id="responsive-navbar-nav">
              <Nav className="mr-auto">
            
              </Nav>
              <Nav>
                <Nav.Link href="/"><span className="nav-num">1/</span>About Us</Nav.Link>
                <Nav.Link href="/success-stories"><span className="nav-num">2/</span>Success Stories</Nav.Link>
                <Nav.Link className={"joinuslink"} href="/join-us"><span className="nav-num">3/</span>Join Us</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Navbar>
          
          <JoinUs />
          <Footer/>
        </Route>
        <Route path="/success-stories">
          <Navbar collapseOnSelect expand="lg"  variant="dark">
            <Navbar.Brand href="/">
              <div className="logo">
                <img src={logo}/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={()=>setIsOpen(isOpen === true ? false : true)}>
              {
                isOpen &&

                <div className="main-menu-icon-close">
                </div>
                ||
                <div className="main-menu-icon">
                </div>
              }
            </Navbar.Toggle>
            
            <Navbar.Collapse className={"fadeInDown"} id="responsive-navbar-nav">
              <Nav className="mr-auto">
            
              </Nav>
              <Nav>
                <Nav.Link href="/"><span className="nav-num">1/</span>About Us</Nav.Link>
                <Nav.Link href="/success-stories"><span className="nav-num">2/</span>Success Stories</Nav.Link>
                <Nav.Link className={"joinuslink"} href="/join-us"><span className="nav-num">3/</span>Join Us</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Navbar>
          
          <SuccessStories />
          <Footer/>
        </Route>
        <Route path="/success-stories-details/:name">
          <Navbar collapseOnSelect expand="lg"  variant="dark">
            <Navbar.Brand href="/">
              <div className="logo">
                <img src={logo}/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={()=>setIsOpen(isOpen === true ? false : true)}>
              {
                isOpen &&

                <div className="main-menu-icon-close">
                </div>
                ||
                <div className="main-menu-icon">
                </div>
              }
            </Navbar.Toggle>
            
            <Navbar.Collapse className={"fadeInDown"} id="responsive-navbar-nav">
              <Nav className="mr-auto">
            
              </Nav>
              <Nav>
                <Nav.Link href="/"><span className="nav-num">1/</span>About Us</Nav.Link>
                <Nav.Link href="/success-stories"><span className="nav-num">2/</span>Success Stories</Nav.Link>
                <Nav.Link className={"joinuslink"} href="/join-us"><span className="nav-num">3/</span>Join Us</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Navbar>
          
          <SuccessStoriesInner />
          <Footer/>
        </Route>
      </Switch>
    </Router>
  );
}


