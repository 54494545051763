import React from "react";
import styles from "./CarouselSlide.module.scss"
import { Carousel } from "react-bootstrap";

const CarouselSlide =  ({images}:{images:Array<string>}) => {
  return (
    <Carousel className={styles.slide} controls={false} indicators={true} touch={true}>
      {
        images && images.map((image, index)=>{
          return(
            <Carousel.Item>
            <img className="d-block w-100" src={image} alt={`slide ${index}`} key={index}/>
          </Carousel.Item>
          )
        })
      }
    </Carousel>
  );
}

export default CarouselSlide;