import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Helmet from "react-helmet";

import StoriesGrid from "../common-components/StoriesGrid";
import styles from "./JoinUs.module.scss"
import Gemma from "../assets/gemma.png";
import Highlight from "../assets/highlight.png";
import Whitegirl from "../assets/white_girl.png";
import Sittinggirl from "../assets/Sittinggirl.png";
import blackwhite from "../assets/blackwhite.png";
import Couple from "../assets/Couple.png";
import Positions, { IPosition } from "./components/Positions";
import JobModal from "./components/Positions/JobModal";
import LetsTalk from "../assets/letstalk.png";
const JoinUs = () =>{

  const [selectedJob, setSelectedJob] = useState<IPosition>();
  return (
    <div className={styles.main}>
      <Helmet>
      <title>KOLS.ASIA Influencer Marketing Agency | Join Us</title>
        <meta name="description" content="KOLS.ASIA | join us"/>
        <meta name="keywords" content="KOLS.ASIA, influencer marketing agency join us page"/>
        {/* FB open graph meta description */}
        <meta property="og:title" content="KOLS.ASIA | Join Us" />
        <meta property="og:description" content="KOLS.ASIA join us" />
      </Helmet>
      <Row>
        <Col>
          <div className={styles.title}> 
            <h1>we’re on a lifelong mission </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>
          
        </Col>
        <Col xs={12} lg={8}>
          <div className={styles["top-image"]}>
            <img src={Gemma}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}  lg={4}>
        </Col>
        <Col xs={12}  lg={8}>
          <div className={styles["creative-text"]}>
            <h1>to empower</h1>
            <div className={styles["with-highlight"]}>
              <h1>
                <div className={styles.imgtext}>
                  <div className={styles.creative}>
                    <span>creative</span>
                    <span className={styles.imgWrapper}><img src={Highlight} /></span>
                  </div>
                  <div>talents</div>
                </div> 
              </h1>
              
            </div>
          </div>
        </Col>
      </Row>

      <Row className={styles.principles}>
        <Col>
          <Row>
            <Col xs={12} lg={4}>
              
            </Col>
            <Col xs={12} lg={8}>
              <div className={styles["principles-title"]}>
                guided by these 5 fundamental principles :
              </div>
            </Col>
          </Row>

          <Row className={styles["principles-list"]}>
            <Col xs={12} lg={4}></Col>
            <Col xs={12} lg={8}>
              <Row>
                <Col xs={12} lg={6}>
                  <h2>01</h2>
                  <h5>Altruism</h5>
                  <p>
                    Guided by the principles of altruism, we strive to always provide more value than what we receive. To be humble, practice gratitude and give back, and give more, wherever and whenever we can.
                  </p>
                </Col>
                <Col xs={12} lg={6}>
                  <h2>02</h2>
                  <h5>Passion</h5>
                  <p>
                    Our passion in all things creative is what keeps us going in pursuing our lifelong ambition in empowering creative talents
                  </p>
                </Col>
              </Row>
              
              <Row>
                <Col xs={12}>
                  <div className={styles["btwn-numbers"]}>
                    <img src={Whitegirl}/>
                  </div>
                  <div className={styles.sittingimgtop}>
                    <img src={Sittinggirl}/>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row  className={styles["principles-list"]}>
            <Col xs={12} lg={4}>
              <h2>03</h2>
              <h5>Empathy</h5>
              <p>
                The key to how we interact, to always deploy empathy to the other person, be it our teammates, client or other stakeholders, to shift perspective from their POV, understanding their intent, and not how they communicate.
              </p>
            </Col>
            <Col xs={12} lg={4}>
              <h2>04</h2>
              <h5>growth</h5>
              <p>
                Constantly competing with our past, not our competitors, and become better versions of ourselves perpetually. To be humble, and acknowledge that there is always room for improvement. To help talents, internal and external, grow and move closer to their goals, to help clients grow their business. Accepting failure, and that growth stems from failure, to fail learn improve repeat, and accelerate this cycle.
              </p>
            </Col>
            <Col xs={12} lg={4}>
              <div className={styles.sittingimg}>
                <img src={Sittinggirl}/>
                <h2>05</h2>
                <h5>integrity</h5>
                <p>
                  To uphold a consistent and uncompromising adherence to strong moral and ethical principals, and conduct with honesty in our actions.
                </p>
              </div>
              <div className={styles.integrity}>
                <h2>05</h2>
                <h5>integrity</h5>
                <p>
                  To uphold a consistent and uncompromising adherence to strong moral and ethical principals, and conduct with honesty in our actions.
                </p>
              </div>
       
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
            <h2 className={`${styles["positiontitle"]} ${styles["hidelg"]}`}>
                <div className={styles.lets}>
                  <img src={LetsTalk}/>
                </div>
                positions
              </h2>
              <div className={styles.blackwhiteimg}>
                <img src={blackwhite}/>
              </div>
            </Col>
            <Col xs={12} lg={6}>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
            </Col>
            <Col xs={12} lg={6}>
              <h2 className={`${styles["positiontitle"]} ${styles["hidesmall"]}`}>
                <div className={styles.lets}>
                  <img src={LetsTalk}/>
                </div>
                positions
              </h2>
            </Col>
          </Row>


          <Row>
            <Col xs={12}>
              <div className={styles.bannerimg}>
                <img src={Couple}/>
              </div>
            </Col>
          </Row>

          <Positions setSelectedJob={setSelectedJob}/>

          {
            selectedJob &&
            <JobModal
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
            />
          }
        </Col>
      </Row>
    </div>
  );
}

export default JoinUs;