import React from "react";
import styles from "./CarouselFlatSlide.module.scss"
import Slider from "react-slick";

import screen1 from "../../../assets/screen1.png";
import screen2 from "../../../assets/screen2.png";
 import "slick-carousel/slick/slick.scss"; 
 import "slick-carousel/slick/slick-theme.scss";



const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 2,
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  cssEase: "linear",
  swipeToSlide: true,
  focusOnSelect: true,
};
const CarouselFlatSlide = ({images}:{images:Array<string>}) =>{
  return (
    <Slider {...settings} className={styles["flat-slide"]}>
      {
        images.map((image, index)=>{
          return(
            <div className={styles.slide} key={index}>
              <img src={image}/>
            </div>
          )
        })
      }
    </Slider>
  );
}

export default CarouselFlatSlide;