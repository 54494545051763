import './setupTests.js'
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

const client = new ApolloClient({
  uri: "https://forms-api.kols.asia/v1/graphql"
});

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(
  <ParallaxProvider>
    <ApolloProvider client={client as any}>
      <App />
    </ApolloProvider>
  </ParallaxProvider>
  , rootElement);
} else {
  render(
  <ParallaxProvider>
    <ApolloProvider client={client as any}>
      <App />
    </ApolloProvider>
  </ParallaxProvider>
  , rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
