import React from "react";
import styles from "./ModalDefault.module.scss"
import { Col, Modal } from "react-bootstrap";
import { Row } from "react-bootstrap";
import CloseBlack from "../../assets/close_black.png";
import Highlight from "../../assets/highlight-black.png";
import logo from "../../assets/logo_white.png"

//@ts-ignore
const ModalDefault = ({updateShowModal, showModal}) =>{
  
  const handleClose = ()=>{
    updateShowModal(false);
    window.location.reload();
  }
  
  return (
    <Modal show={showModal} className={styles.modal} onHide={handleClose}>
      <Modal.Body>

        <span className={styles["close-btn"]} onClick={handleClose}>
          <img src={CloseBlack}/>
        </span>
        <Row>
          <Col className={styles.change_img}>
            <div className={styles.jobs}>
              <div className={styles.img}>
              </div>
            </div>
          </Col>
          <Col>
            <div className={styles.logo}>
              <img src={logo}/>
            </div>
            <div className={styles["creative-text"]}>
              <h3>hey there, thank you</h3>
              <div className={styles["with-highlight"]}>
                <h3>
                  <div className={styles.imgtext}>
                    <div>for</div> &nbsp;
                    <div className={styles.creative}>
                      <span>your interest!</span>
                      <span className={styles.imgWrapper}><img src={Highlight} /></span>
                    </div>
                  </div> 
                </h3>
              </div>

              <div>
                <h3 className={styles["gray-h3"]}>one of our growth specialists will be in touch real soon! </h3>
              </div>

              <div>
                <h3 className={styles["black-h3"]}>can't wait to achieve some amazing things together! </h3>
              </div>
            </div>

            
          </Col>
        </Row>
        
      </Modal.Body>
    </Modal>
  );
}

export default ModalDefault;