import { FunctionComponent } from "react";
import React from "react";
import styles from "./LandingPageMobile.module.css";

const LandingPageMobile: FunctionComponent = () => {
  return (
    <div className={styles.landingPageMobile}>
      <div className={styles.navigation}>
        <img className={styles.logoIcon} alt="" src="/logo.svg" />
        <a href="https://merchant.kols.asia">
          <div className={styles.button}>
              <div className={styles.content}>
                <img className={styles.icon} alt="" src="/icon7.svg" />
                <div className={styles.button1}>For Merchant</div>
                <img className={styles.icon1} alt="" src="/icon2.svg" />
              </div>
          </div>
        </a>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.group89831Parent}>
          <img
            className={styles.group89831}
            alt=""
            src="/group-8983-1@2x.png"
          />
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.areYouACreatorParent}>
                <b className={styles.areYouA}>Are you a creator?</b>
                <div className={styles.monetizeYourContent}>
                  Monetize your content through brand partnerships, the intuitive KOLs.Asia interface allows you to sort through campaigns from your favorite brands and find the ones that are most relevant to your audience.
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.ellipseParent}>
                  <div className={styles.frameChild} />
                  <div
                    className={styles.directChat}
                  >{`Direct chat & Communication.`}</div>
                </div>
                <div className={styles.ellipseGroup}>
                  <div className={styles.frameChild} />
                  <div className={styles.appkolsasia}>
                    Live campaign tracking.
                  </div>
                </div>
                <div className={styles.ellipseGroup}>
                  <div className={styles.frameChild} />
                  <div className={styles.appkolsasia}>
                    Real time alerts – Never forget a deadline.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.getKolsasiaNowParent}>
              <b className={styles.getKolsasiaNow}>Get KOLs.Asia now!</b>
              <div className={styles.appstore}>
                <a href="https://apps.apple.com/us/app/kols-asia/id1594976667" target="_blank">
                  <img
                    className={styles.appstoreIcon}
                    alt=""
                    src="/appstore.svg"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.kolsasia.kol" target="_blank">
                  <img
                    className={styles.appstoreIcon}
                    alt=""
                    src="/googleplay.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.groupParent}>
          <div className={styles.groupContainer}>
            <div className={styles.image1568Parent}>
              <img
                className={styles.image1568Icon}
                alt=""
                src="/image-1568@2x.png"
              />
              <img
                className={styles.groupChild}
                alt=""
                src="/rectangle-25526@2x.png"
              />
            </div>
            <img
              className={styles.image1569Icon}
              alt=""
              src="/image-1569@2x.png"
            />
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.areYouACreatorParent}>
              <b className={styles.areYouA}>Are you a brand owner?</b>
              <div className={styles.monetizeYourContent}>
                No more agency fees, manage your campaigns with ease!
              </div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.ellipseParent}>
                <div className={styles.frameChild} />
                <div className={styles.directChat}>
                  Simple set up, briefing process and KOL selection leaves you in full control.
                </div>
              </div>
              <div className={styles.ellipseParent}>
                <div className={styles.frameChild} />
                <div className={styles.directChat}>
                  <p className={styles.clearReportingOf}>Clear reporting of results, know where you’re getting the most value.</p>
                </div>
              </div>
              <div className={styles.ellipseParent}>
                <div className={styles.frameChild} />
                <div className={styles.appkolsasia}>
                  Live chat functionality ensures KOL partners stay on track.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.registerAsAMerchantNowParent}>
            <b className={styles.appkolsasia}>Register as a merchant now!</b>
            <a href="https://merchant.kols.asia">
              <div className={styles.button2}>
                <div className={styles.content1}>
                  <img className={styles.icon2} alt="" src="/icon5.svg" />
                  <div className={styles.appkolsasia}>Register</div>
                  <img className={styles.icon3} alt="" src="/icon6.svg" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.lineIcon} />
        <div className={styles.footer1}>
          <div className={styles.button3}>
            <a href="./tos">
              <div className={styles.button4}>
                <div className={styles.content}>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                  <div className={styles.appkolsasia}>Terms of Services</div>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                </div>
              </div>
            </a>
            <a href="./privacy">
              <div className={styles.button4}>
                <div className={styles.content}>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                  <div className={styles.appkolsasia}>Data Privacy</div>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                </div>
              </div>
            </a>
            <a href="https://www.kols.asia/">
              <div className={styles.button4}>
                <div className={styles.content}>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                  <div className={styles.appkolsasia}>About kols.asia</div>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                </div>
              </div>
            </a>
          </div>
          <div className={styles.copywrite2023}>
            Copywrite © 2023 KA Technologies Sdn Bhd
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageMobile;
