import React from "react";
import styles from "./Footer.module.scss"
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';

const Footer = () =>{
  const isMobile = useMediaQuery({ maxWidth: 991 });
  if (isMobile) {
    return (
      <div style={{padding:'20px'}}>
        <Col>
        <Row><a href="mailto:hello@kols.asia" style={{color:'white'}}>CONTACT US</a></Row>
        <Row><a href="./privacy" style={{color:'white'}} target="_blank" rel="noopener noreferrer">PRIVACY</a></Row>
        <Row><a href="./tos" style={{color:'white'}} target="_blank" rel="noopener noreferrer">TERM & CONDITION</a></Row>
        <Row><a href="http://instagram.com/kols.asia" style={{color:'white'}} target="_blank" rel="noopener noreferrer">INSTAGRAM</a></Row>
        <Row><a href="https://www.facebook.com/kols.asia" style={{color:'white'}} target="_blank" rel="noopener noreferrer">FACEBOOK</a></Row>
        <Row><a href="https://www.linkedin.com/company/kolsasia" style={{color:'white'}} target="_blank" rel="noopener noreferrer">LINKEDIN</a></Row>
        <Row><a href="https://synchronized.studio/" style={{color:'white'}} target="_blank" rel="noopener noreferrer">Designed by Synchronized Studio</a></Row>
        <div style={{color:'#717171', marginTop:'15px'}}>
          <Row>POPCULTUREMEDIA.</Row>
          <Row>CONTE DEFEE MARKETING (M) SDN BHD.</Row>
          <Row>ALL RIGHTS RESERVED.</Row>
        </div>
      </Col>
      </div>
    );
  }
  return (
    <footer className="container-fluid">
      <Row>
        <Col> 
          <Row className={styles.firstColumn}>
            <Col xs={2} className={`${styles.allrightsmobile} d-lg-none d-xl-block `}> 
              <div className="text-uppercase" style={{marginLeft: '19px'}}>PopcultureMedia. </div > 
              <div className="text-uppercase" style={{marginLeft: '19px'}}>Conte Defee Marketing (M) Sdn Bhd. </div>
              <div className="text-uppercase" style={{marginLeft: '19px'}}> All rights reserved.</div>
            </Col>
            <Col className={styles.contact} style={{display:'flex', justifyContent:'center'}}> <a href="mailto:hello@kols.asia">contact us</a> </Col>
            <Col className={styles.contact} style={{display:'flex', justifyContent:'center'}}> <a href="./privacy" target="_blank" rel="noopener noreferrer">PRIVACY</a> </Col>
            <Col className={styles.contact} style={{display:'flex', justifyContent:'center'}}> <a href="./tos" target="_blank" rel="noopener noreferrer">TERM & CONDITION</a> </Col>
            <Col className={styles.contact} style={{display:'flex', justifyContent:'center'}}><a href="http://instagram.com/kols.asia"  target="_blank">INSTAGRAM</a></Col>
            <Col className={styles.contact} style={{display:'flex', justifyContent:'center'}}><a href="https://www.facebook.com/kols.asia"  target="_blank">FACEBOOK</a></Col>
            <Col className={styles.contact} style={{display:'flex', justifyContent:'center'}}><a href="https://www.linkedin.com/company/kolsasia"  target="_blank">LINKEDIN</a></Col>
            <Col xs={2} className={styles.designedBy} style={{display:'flex', alignItems:'flex-end'}}> <a href="https://synchronized.studio/"  target="_blank">Designed by Synchronized Studio</a> </Col>

          </Row>
        </Col>
        
      </Row>
      
    </footer>
  );
}

export default Footer;