import { FunctionComponent } from "react";
import React from "react";
import styles from "./TermPolicyMobile.module.css";

const TermPolicyMobile: FunctionComponent = () => {
  return (
    <html className={styles.mainPage}>
      <div className={styles.navigation}>
        <a href="./get"><img className={styles.logoIcon} alt="" src="/logo.svg" /></a>
        <a href="https://merchant.kols.asia">
          <div className={styles.button}>
              <div className={styles.content}>
                <img className={styles.icon} alt="" src="/icon7.svg" />
                <div className={styles.button1}>For Merchant</div>
                <img className={styles.icon1} alt="" src="/icon2.svg" />
              </div>
          </div>
        </a>
      </div>


      <div className={styles.scrollableContent}>
        <p className={styles.privacyPolicyHeader}>Terms of Service</p>
        <p className={styles.privacyPolicyContent}>
        KA Technologies sdn bhd Terms of Service

Last Updated: 22 November 2024 <br/><br/>

1. Acceptance of Terms<br/>
Welcome to KOLs.Asia ("App"). By accessing or using the App and its services, you agree to be bound by these Terms of Service. If you do not agree with these Terms of Service, please refrain from using the App.<br/><br/>

2. App Usage<br/>
You must be at least 16 years old to use the App. By using the App, you confirm that you meet this age requirement.<br/><br/>

3. User Responsibilities<br/>
  • You are responsible for maintaining the confidentiality of your account information, including your login credentials and password.<br/>
  • You are responsible for all activities that occur under your account.<br/>
  • You must not use the App for any illegal, harmful, or unauthorized purposes.<br/>
  • You must comply with all applicable laws and regulations when using the App.<br/><br/>

4. Prohibited Activities<br/>
When using the App, you agree not to:<br/>
  • Post or promote any offensive, harmful, or illegal content.<br/>
  • Use the App to harass, threaten, or harm others.<br/>
  • Impersonate any person or entity or misrepresent your affiliation with any person or entity.<br/>
  • Interfere with or disrupt the App's functionality or servers.<br/>
  • Engage in any fraudulent activities.<br/><br/>

5. Payment and Transactions<br/>
The App provides a payment portal to facilitate transactions between service providers and users. By using this portal, you agree to our Payment Terms. You are responsible for all payments made through your account, and you must ensure that your payment information is accurate and up-to-date. We reserve the right to refuse or cancel transactions in our sole and absolute discretion.<br/><br/>

6. Intellectual Property<br/>
All content and materials provided on the App, including text, graphics, logos, images, and software, are the property of KA Technologies sdn bhd ("we," "our," or "us") or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or use any content from the App without our prior written consent.<br/><br/>

7. User Content<br/>
By posting content on our app, you grant us a non-exclusive, royalty-free, worldwide license to use, modify, and display your content for app-related purposes. You are solely responsible for the content you post, and you must ensure that it complies with our User Content Policy.<br/><br/>

8. Termination<br/>
We reserve the right to terminate or suspend your account at any time without prior notice if you violate these Terms of Service or engage in any harmful activities on the App.<br/><br/>

9. Limitation of Liability<br/>
We shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of the App and/or its services.<br/><br/>

10. Governing Law<br/>
These Terms of Service shall be governed by and construed in accordance with the laws of Malaysia.<br/><br/>

11. Changes to Terms<br/>
We may update these Terms of Service from time to time. Any changes will be posted in the App.<br/><br/>

12. Contact Us<br/>
If you have any questions or concerns about these Terms of Service, please contact us at hello@kols.asia.<br/><br/>
        </p>
 

      </div>

      <div className={styles.footer}>
        <div className={styles.lineIcon} />
        <div className={styles.footer1}>
          <div className={styles.button3}>
            <a href="./privacy">
              <div className={styles.button4}>
                <div className={styles.content}>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                  <div className={styles.appkolsasia}>Data Privacy</div>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                </div>
              </div>
            </a>
            <a href="https://www.kols.asia/">
              <div className={styles.button4}>
                <div className={styles.content}>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                  <div className={styles.appkolsasia}>About kols.asia</div>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                </div>
              </div>
            </a>
          </div>
          <div className={styles.copywrite2023}>
            Copywrite © 2023 KA Technologies Sdn Bhd
          </div>
        </div>
      </div>
    </html>
  );
}

export default TermPolicyMobile;
