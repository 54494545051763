import React from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import StoriesGrid, { ISuccessStory } from "../common-components/StoriesGrid";
import CarouselFlatSlide from "./components/CarouselFlatSlide";
import styles from "./SuccessStoriesInner.module.scss"
import LancomeBottle from "../assets/Lancome_Bottle.png";
import SkinCareUse from "../assets/Skincare_use.png";
import Mirrow from "../assets/Mirrow.png";
import { Animated } from "react-animated-css";
import ROI from "../assets/roi.png"
const SuccessStoriesInner = ({
  history
}:{
  history:RouteComponentProps
}) =>{

  console.log("PROPS", history.location)
  const story:ISuccessStory = (history.location.state || {} )as ISuccessStory || {}; 
  return (
    <div className={styles.main}>
      <Row className={styles["title-wrapper"]}>
        <Col>
          <div className={styles.title}> 
            <h1>{story.name}</h1>
            <span>{story.brief_description}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>
        </Col>
        <Col xs={12} lg={8}>
          <div className={styles["image-wrapper"]}>
            <img src={story.image}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>
        </Col>
        <Col  xs={12} lg={8}>
          <div className={styles["description"]}>
            {story.full_description}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>
        </Col>
        <Col  xs={12} lg={8}>
          <div className={styles["attributes"]}>
            <div className={styles.border}/>

            <div className={styles["text-wrapper"]}>
              <span className={styles.label}>
                campaign period
              </span>
              <span  className={styles.value}>
                {story.campaign_period}
              </span>
            </div>

            <div/>
          </div>

          <div className={styles["attributes"]}>
            <div className={styles.border}/>

            <div className={styles["text-wrapper"]}>
              <span className={styles.label}>
                total kols engaged
              </span>
              <span  className={styles.value}>
                {story.total_kols_engaged}
              </span>
            </div>

            <div/>
          </div>

          <div className={styles["attributes"]}>
            <div className={styles.border}/>

            <div className={styles["text-wrapper"]}>
              <span className={styles.label}>
                deliverables
              </span>
              <span  className={styles.value}>
                {story.deliverables}
              </span>
            </div>

            <div className={styles.border}/>
          </div>
        </Col>
      </Row>
      <Row className={styles.nums}>
        <Col xs={12} lg={4}>
          <div className={styles["numbers"]}>
            <div className={styles.num}>{story.total_content ? story.total_content.toLocaleString() : ""}</div>
            <div className={styles.txt}>total contents created</div>
          </div>
        </Col>
        <Col  xs={12} lg={8}>
          <div className={styles["numbers"]}>
     
            <div className={styles.num}>
              <div className={styles.roi}>
                <img src={ROI}/>
              </div>
              {story.total_reach ? story.total_reach.toLocaleString() : ""}
            </div>
            <div className={styles.txt}>total reach achieved</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>

        </Col>
        <Col  xs={12} lg={8}>
          <div className={styles["numbers"]}>
            <div className={styles.num}>{story.total_engagement ? story.total_engagement.toLocaleString() : ""}</div>
            <div className={styles.txt}>total engagements achieved</div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {
            story.product_images &&
            <CarouselFlatSlide images={story.product_images}/>
          }
          
        </Col>
      </Row>

      <Row className={styles.sentiments}>
        <Col xs={12} lg={4}>
        </Col>
        <Col xs={12} lg={8}>
          <Row>
            <Col>
            {
              story.sentiments && story.sentiments.length !== 0 && (
              <h2>Sentiments</h2>
            )}
            </Col>
          </Row>
          <Row>
            
                {story.sentiments && story.sentiments.map((obj, index)=>{
                  return <Col xs={12} lg={6} className={styles.cols}>
                    <span className={styles.username}>{obj.username}</span>
                    <span className={styles.small_comment}>{obj.small_comment ? obj.small_comment : ""}</span>
                    <span className={styles.username}>{obj.username1}</span>
                    <span className={styles.small_comment}>{obj.small_comment1 ? obj.small_comment1 : ""}</span>
                    <span className={styles.usernameMain}>{obj.usernameMain}</span>
                    <span className={styles.comment}>{obj.comment}</span>
                  </Col>
                })}
            
          </Row>


        </Col>
      </Row>

      <StoriesGrid title={"other stories"}/>
    </div>
  );
}

//@ts-ignore
export default withRouter(SuccessStoriesInner);