import React from "react";
import styles from "./Positions.module.scss"
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

export type IPosition = {
  name?:string;
  description1?:string;
  description2?:string;
  your_are1?: string[];
  you_will?: string[];
  ideal_candidate?: string[];
  you_are?:string;
  job_is?:string;
}
const Positions = ({
  setSelectedJob
}:{setSelectedJob:(position:IPosition)=>void}) =>{

  const allpositions:Array<IPosition> = [{
    name:"Business Development",
    description1:"",
    description2:"",
    your_are1: [
      "1. Naturally communicative, highly self-driven and hungry for success, passionate, and adaptable.",
      "2. Hyper organized, even-tempered, respectful and willing and able to communicate and collaborate with a team of super talented people.",
      "3. Able to face countless client rejections, and still uphold a high level of energy and positivity",
      "4. Be prepared to manage day-to-day communications between the client and our team (and a whole host of other people in between.)",
      "5. Well versed in common marketing & media principles with a strong understanding of creative and media across major digital and social channels.",
      "6. We live and breathe the social & digital space (and love nothing more than learning about something new) so we expect all of our teammates to have an innate curiosity",
      "7. Relentlessly focused on building strong client relationships–becoming a trusted day-to-day partner with a deep understanding of your client’s industry (and ours) & the challenges that they face."
    ],
    you_are: "",
    ideal_candidate: [
      "1. At least 2-5 Year(s) of advertising / media / social / digital agency experience working on digital / social strategies + campaigns, including client-facing experience",
      "2. A sparkling personality with an infectious smile that’ll always bring a positive vibe to the room",
      "3. An interest and comfort level in standing in front of a client or a room full of peers and giving their opinion or a presentation",
      "4. An insatiable curiosity and knowledge about all things social, web, and mobile",
      "5. Enjoys partying while still being able to maintain a high level of consistent performance"
    ],
    job_is:""
  },{
    name:"Influencer Manager",
    description1:"We are seeking a talented Influencer Manager who will manage and grow our influencer roster.",
    description2:" Ideally, we want someone who is familiar with the PR landscape and bilingual who knows the social media in and out.",
    you_are:"",
    you_will: [
      "1. You will assist in identifying potential & rising stars in the social media landscape. Key focused platforms are Tiktok and Twitch.",
      "2. You will work on creating the content and creative strategies for all KOL assets, as well as within a certain campaign, the brand's role and engagement with that content, and develop KOL briefing guides for video and photo production.",
      "3. You will work on guidelines specific to platform in order to make sure assets are as engaging and authentic as possible, always building on brand equity."
    ],
    ideal_candidate: [
      "1. Do you know social media inside & out? ",
      "2. Have you worked either as an influencer, or with an influencer agency, to create amazing content for brands?",
      "3. Are you experienced in writing clear briefs, and communicating with influencers from micro to celeb?",
      "4. Do you have an “eye” for design and eye-catching content?",
      "5. Are you bi-lingual (Fluent in EN and CN/MY)?",
      "6. Then this is the role for you!"
    ],
    job_is:""
  },{
    name:"Content Creator",
    description1:"We are looking for creators, creative industry rogues, Instagram story lovers, #foryourpage admirers. We want to create cool shit that the internet wants to see. We want talents who can come up with compelling content ideas and then create them! ",
    description2:"We’re looking for the unconventional, less-obvious, unseasoned (or over seasoned) creative pros, but above all - passionate makers. Just as long as everyday you love coming up with insightful and relevant content ideas and have the skills to make them.",
    your_are1: [
      "1. Uses and creates on social media daily",
      "2. Has an ability to make relevant content across all platforms and has extraordinary skills in at least one if not all of these: design, video editing, social media copywriting.",
      "3. Videography or design education OR equivalent relevant experience, all applicants will be asked to provide recent content examples from social media accounts.",
      "4. Shows a demonstrable passion, curiosity and experience in making creative content on social media platforms - TikTok videos, Instagram visuals,",
      "5. Proficient in one or many of the Adobe Creative Suite programs and eager to continue to learn and evolve in other efficient and effective ways to make social first content: Adobe Creative Suite, mobile creator apps and in app editing (Instagram story creation, TikTok editing etc.)",
      "6. Responsible, accountable, deeply passionate about their own personal interests and hobbies, and of course kind, compassionate and empathetic.",
    ],
    you_are:"",
    job_is:"Creating emotional, impactful, objective oriented content for KOLs.Asia.\nMastering the ability to turn any branded content into a volume of social commodity (clips, gifs, graphics, photos, stories, copy, etc.) .\nWorking with a wide range and quality of video and static imagery - from a collection of photography to short video clips - and turning them content assets built for individual platforms and audiences.\n\nHoning your design, editing and blurb writing skills utilizing standard industry software and social platforms.\nWrangling and rolling with your teammates to service rapid turnaround times, capture trending moments, deliver and receive feedback, all while keeping a clear, enthusiastic and proactive attitude towards content creation."
  }]
  return (
    <Row>
      <Col xs={12} lg={6}> 
        {
          allpositions.map((obj, index)=>{
            return <div
              onClick={()=>setSelectedJob(obj)}
              className={styles["position-name"]}
            >
              {obj.name} <span className={`position-num${index+1}`}>{(index + 1) <= 9 ? "0" + (index + 1) : index + 1}</span>
            </div>
          })
        }
      </Col>
      <Col xs={12} lg={6}>
      </Col>
    </Row>
  );
}

export default Positions;